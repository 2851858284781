import {
  getAccessToken,
  IMiddleware,
  InternalAxiosRequestConfig
} from "@bps/http-client";
import { StorageProperties } from "@libs/constants/storage-properties.ts";

export class AuthorizationMiddleware
  implements IMiddleware<InternalAxiosRequestConfig>
{
  constructor(private noAuthHeader?: string) {}
  onFulfilled = async (
    config: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> => {
    if (
      this.noAuthHeader &&
      typeof config.headers === "object" &&
      config.headers.hasOwnProperty(this.noAuthHeader)
    ) {
      delete config.headers[this.noAuthHeader];
      return config;
    }

    const token = await getAccessToken(true, () =>
      localStorage.removeItem(StorageProperties.locked)
    );
    if (config.headers && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  };
}
