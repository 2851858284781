import { DependencyList, useEffect } from "react";
import ReactGA from "react-ga4";
// eslint-disable-next-line import/extensions
import { GA4 } from "react-ga4/types/ga4";
import { useLocation } from "react-router-dom";

import { appContext } from "@libs/config/app-context.utils.ts";

/* eslint-disable camelcase */
type AnalyticsEffectCallback = (ga: GA4) => void;

const {
  config: { gaMeasurementId }
} = appContext;

export enum GAEvents {
  dialogOpened = "dialog_opened",
  clinicalDockSelected = "clinical_dock_selected",
  authenticated = "authenticated",
  userVoiceTriggerInit = "user_voice_trigger_init"
}

export const useGoogleAnalyticsInit = () => {
  useEffect(() => {
    if (!gaMeasurementId) return;

    ReactGA.initialize(gaMeasurementId, {
      gaOptions: {
        // See: https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#create
      }
    });
  }, []);
};

export const useGoogleAnalyticsEffect = (
  effect?: AnalyticsEffectCallback,
  deps: DependencyList = []
) => {
  useEffect(() => {
    if (!gaMeasurementId) return;
    effect && effect(ReactGA);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gaMeasurementId, ...deps]);

  return ReactGA;
};

export const useGoogleAnalyticsPageTracking = () => {
  const location = useLocation();

  useGoogleAnalyticsEffect(
    ga => {
      ga.send({
        hitType: "pageview",
        page_location: `${location.pathname}${location.search}`
      });
    },
    [location]
  );
};

export const useGoogleAnalyticsEventTracking = (name: GAEvents) => {
  const ga = useGoogleAnalyticsEffect(undefined, []);
  return (params: object) => ga.event(name, params);
};
