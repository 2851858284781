import { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { excludedRefDataFields, RefDataDto } from "@libs/api/ref-data/dto.ts";
import { withoutFields } from "@libs/api/utils/paging.utils.ts";
import {
  AutofillDto,
  TemplateArgs,
  TemplateDataModelDataDto,
  TemplateDataModelDto,
  TemplateDeleteArgsDto,
  TemplateDto,
  TemplateRenderDto,
  TemplateRenderOptions
} from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { IDocumentGateway } from "@libs/gateways/document/DocumentGateway.interface.ts";

export class DocumentGateway implements IDocumentGateway {
  constructor(private api: AxiosInstance) {}

  getTemplates(args: TemplateArgs): Promise<TemplateDto[]> {
    return this.api
      .get<TemplateDto[]>(`/getTemplates?${stringify(args)}`)
      .then(x => x.data);
  }
  getAllTemplates(): Promise<TemplateDto[]> {
    return this.api.get<TemplateDto[]>("/templates").then(x => x.data);
  }

  getTemplate(templateId: string): Promise<TemplateDto> {
    return this.api
      .get<TemplateDto>(`/templates/${templateId}`)
      .then(x => x.data);
  }

  renderTemplate(
    templateId: string,
    options: TemplateRenderOptions
  ): Promise<TemplateRenderDto> {
    return this.api
      .post<TemplateRenderDto>(`/templates/${templateId}/render`, options)
      .then(x => x.data);
  }

  getTemplateDataModel(
    templateType: string,
    options: TemplateRenderOptions
  ): Promise<TemplateDataModelDto> {
    // Convert options to JSON and Based64-encoded
    const encodedOption = btoa(JSON.stringify(options));
    return this.api
      .get<TemplateDataModelDto>(
        `/templateDataModels/${templateType}?context=${encodedOption}`
      )
      .then(x => x.data);
  }

  getTemplateDataModelData(
    templateType: string,
    propertyPath: string,
    options: TemplateRenderOptions
  ): Promise<TemplateDataModelDataDto> {
    // Convert options to JSON and Based64-encoded
    const encodedOption = btoa(JSON.stringify(options));
    return this.api
      .get<TemplateDataModelDto>(
        `/templateDataModels/${templateType}/data/${propertyPath}?context=${encodedOption}`
      )
      .then(x => x.data);
  }

  createTemplate(template: TemplateDto) {
    return this.api.post<TemplateDto>("/templates", template).then(x => x.data);
  }

  deleteTemplate(
    templateId: string,
    args: TemplateDeleteArgsDto
  ): Promise<void> {
    return this.api.delete(`/templates/${templateId}?${stringify(args)}`);
  }

  private getRef<T extends RefDataDto = RefDataDto>(name: string) {
    return this.api
      .get<T[]>(`/ref/${name}?${withoutFields(excludedRefDataFields)}`)
      .then(x => x.data);
  }

  getReasonsForDeleteTemplate() {
    return this.getRef("templateDeleteReasons");
  }
  editTemplate(template: TemplateDto) {
    return this.api
      .put<TemplateDto>(`/templates/${template.id}`, template)
      .then(x => x.data);
  }

  getAutofills(): Promise<AutofillDto[]> {
    return this.api.get<AutofillDto[]>("/autofills").then(x => x.data);
  }
}
