import merge from "deepmerge";

import { IConfig } from "@libs/config/app-context.types.ts";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

if (
  import.meta.env.NODE_ENV !== "test" &&
  !(window["appConfig"] as IConfig)?.authentication
) {
  const MESSAGE = "Application cannot start: missing configuration.";
  throw Error(MESSAGE);
}

const config = merge<RecursivePartial<IConfig>>(
  {
    authentication: {
      cacheLocation: "localStorage",
      clientId: ""
    },
    b2cAuthentication: {
      clientId: "",
      cacheLocation: "localStorage",
      signinPolicy: "",
      userSignupPolicy: "",
      tenantSignupPolicy: "",
      resetPasswordPolicy: "",
      resetMfaPolicy: "",
      resetPinPolicy: "",
      tenantName: ""
    },
    accBaseUrl: "/api/acc",
    billingBaseURL: "/api/billing",
    bookingBaseURL: "/api/booking",
    commsBaseURL: "/api/comms",
    clinicalBaseURL: "/api/clinical",
    documentBaseURL: "/api/document",
    reportsBaseURL: "/api/reports",
    bhbBaseURL: "/api/bhb",
    bhacBaseURL: "/api/bhac",
    formsBaseURL: "/api/forms",
    coreBaseURL: "/api/core",
    drugsBaseURL: "/api/drugs",
    appointmentRemindersBaseURL: "/api/appointmentReminders",
    inboxDocumentsBaseURL: "/api/docworkflow",
    userExperienceBaseURL: "/api/ux",
    notificationDuration: 4000,
    practiceBaseURL: "/api/practice",
    signalrBaseURL: "/hubs",
    notificationBaseURL: "/api/notifications",
    customIntegrationsBaseUrl: "/api/customintegrations",
    templateManagementURL: "/api/templateManagement"
  },
  (window as any).appConfig || {}
) as IConfig;

/**
 * Application context data
 */
export const appContext: {
  /**
   * Configuration data
   */
  config: IConfig;
  /**
   * Application Insight instance
   */
  appInsights?: ApplicationInsights;
} = {
  config
};
