import { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { toQueryResult, withPaging } from "@libs/api/utils/paging.utils.ts";
import {
  DrugClassDto,
  DrugMedicationSummaryDto,
  DrugProductNameDto,
  DrugProductPackDto,
  GetMedicationSummaryDto,
  IngredientDto,
  NonDrugDto,
  PbsListingDto,
  ReactionAgentTypeDto
} from "@libs/gateways/drugs/DrugsGateway.dtos.ts";
import { IDrugsGateway } from "@libs/gateways/drugs/DrugsGateway.interface.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";

export type ReactionFilter = PagingOptions & {
  search?: string;
};

export class DrugsGateway implements IDrugsGateway {
  constructor(private api: AxiosInstance) {}

  public async getProductNames(searchName: string) {
    return this.api
      .get<DrugProductNameDto[]>("/productNames", {
        params: {
          searchName
        }
      })
      .then(x => x.data);
  }

  public async getProductPacks(productNameId: string) {
    return this.api
      .get<DrugProductPackDto[]>("/productPacks", {
        params: {
          productNameId
        }
      })
      .then(x => x.data);
  }

  async getReactionAgentTypes(
    filter: ReactionFilter
  ): Promise<QueryResult<ReactionAgentTypeDto>> {
    return this.api
      .get<ReactionAgentTypeDto[]>(
        `/reactionAgentTypes?${stringify(withPaging(filter))}`
      )
      .then(toQueryResult);
  }

  public async getMedicationSummary(params: GetMedicationSummaryDto) {
    return this.api
      .get<DrugMedicationSummaryDto[]>("/medicationsummary", {
        params
      })
      .then(x => x.data);
  }

  public async getPbsListings() {
    return this.api.get<PbsListingDto[]>("/ref/pbsListings").then(x => x.data);
  }

  public async getIngredient(ingredientId: string) {
    return this.api
      .get<IngredientDto>(`/ingredients/${ingredientId}`)
      .then(x => x.data);
  }

  public async getDrugClasses(drugClassId: string) {
    return this.api
      .get<DrugClassDto>(`/drugClasses/${drugClassId}`)
      .then(x => x.data);
  }

  public async getNonDrug(nonDrugCode: string) {
    return this.api
      .get<NonDrugDto>(`/nonDrugs/${nonDrugCode}`)
      .then(x => x.data);
  }
}
