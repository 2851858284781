import { ARRAY_ERROR, FORM_ERROR } from "final-form";

import { composeValidators } from "./composeValidators.ts";
import { predicate } from "./fieldValidators.ts";
import { isValid } from "./isValid.ts";
import {
  ArrayValidationResult,
  FieldValidationRule,
  FieldValidator,
  ValidationResult
} from "./validation.types.ts";

export abstract class BaseValidator<
  TValue extends any = any,
  TRoot extends object = any
> {
  validate: (
    value: TValue,
    root?: TRoot
  ) => ValidationResult | Record<keyof TRoot, string>;
}

export class Validator<
  T extends object,
  TRoot extends object = any
> extends BaseValidator<T, TRoot> {
  protected fieldRules = new Array<FieldValidationRule>();
  protected formRules = new Array<
    (value: T, root?: TRoot) => false | string | undefined
  >();

  validate = (value: T, root?: TRoot): Record<keyof TRoot, string> => {
    const validationResults: ValidationResult[] = this.fieldRules.map(
      fieldRule => {
        const fieldValue = value && value[fieldRule.field];

        if (fieldRule.isArrayFieldValidation) {
          const items = Array.isArray(fieldValue) ? fieldValue : [];

          const itemsValidationResults: Array<
            boolean | string | ValidationResult
          > = items.map(
            item =>
              fieldRule.fieldValidator(item, value, root ?? value) || false
          );

          return itemsValidationResults.every(isValid)
            ? {}
            : { [fieldRule.field]: itemsValidationResults };
        }

        const error = fieldRule.fieldValidator(
          fieldValue,
          value,
          root ?? value
        );

        /**
         * setting an error for the array itself requires a special
         * type of error shape:
         * See https://final-form.org/docs/final-form/api#array_error
         */
        if (fieldRule.isArrayValidation) {
          const result: ArrayValidationResult = [];
          if (error) {
            result[ARRAY_ERROR] = error as string;
            return { [fieldRule.field]: result };
          }
          return {};
        }

        return error ? { [fieldRule.field]: error } : {};
      }
    );

    const formValidationResult = this.formRules.reduce(
      (result, formRule) => result || formRule(value, root),
      undefined
    );

    const allResults = Object.assign({}, ...validationResults);

    if (formValidationResult) {
      allResults[FORM_ERROR] = formValidationResult;
    }

    return allResults;
  };

  protected forArrayField(
    field: keyof T & string,
    rules: FieldValidator | FieldValidator[] | BaseValidator
  ) {
    return this.forField(field, rules, { isArrayFieldValidation: true });
  }

  protected forArray(
    field: keyof T & string,
    rules: FieldValidator | FieldValidator[] | BaseValidator
  ) {
    return this.forField(field, rules, { isArrayValidation: true });
  }

  protected forField<TKey extends keyof T & string>(
    field: TKey,
    rules:
      | FieldValidator<T[TKey], T, TRoot>
      | FieldValidator<T[TKey], T, TRoot>[]
      | BaseValidator<T[TKey], TRoot>,
    {
      isArrayFieldValidation,
      isArrayValidation,
      when
    }: {
      isArrayFieldValidation?: boolean;
      isArrayValidation?: boolean;
      when?: (value: T[TKey], parent: T, root: TRoot) => boolean;
    } = {}
  ) {
    let fieldValidator: FieldValidationRule["fieldValidator"];

    if (rules instanceof BaseValidator) {
      fieldValidator = (value: any, root: any) => rules.validate(value, root);
    } else if (Array.isArray(rules)) {
      fieldValidator = composeValidators(rules);
    } else {
      fieldValidator = rules;
    }

    if (when) {
      fieldValidator = predicate(when, fieldValidator);
    }

    this.fieldRules.push({
      field,
      fieldValidator,
      isArrayFieldValidation,
      isArrayValidation
    });
  }

  forForm(
    formRule: (value: T, root?: TRoot) => false | string | undefined,
    {
      when
    }: {
      when?: (value: T, root?: TRoot) => boolean;
    } = {}
  ) {
    this.formRules.push(
      when
        ? (value, root) =>
            when(value, root) ? formRule(value, root) : undefined
        : formRule
    );
  }
}
