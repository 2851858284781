import { isKeyHotkey } from "is-hotkey";

export class Hotkey {
  /**
   * returns a checking function that you can re-use for multiple events against a hotkey.
   * @param hotkey - the hotkey sequence
   */
  public static getHotkeyChecker(hotkey: string | readonly string[]) {
    return isKeyHotkey(hotkey);
  }

  /**
   * checks if the hot key matches the keyboard event
   * @param hotkey - the hotkey sequence
   * * @param event - the KeyboardEvent to match against
   */
  public static isHotkey(
    hotkey: string | readonly string[],
    event: KeyboardEvent
  ) {
    return isKeyHotkey(hotkey, event);
  }
}
