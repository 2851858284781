import { ValidationResult } from "./validation.types.ts";

export const flattenErrors = (result: ValidationResult): string[] => {
  const errors: string[] = [];
  Object.keys(result).forEach(key => {
    const value = result[key];
    if (value && typeof value === "object") {
      errors.push(...flattenErrors(value as ValidationResult));
    }

    if (value && typeof value === "string") {
      errors.push(value);
    }
  });

  return errors;
};
