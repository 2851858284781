import { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { excludedRefDataFields, RefDataDto } from "@libs/api/ref-data/dto.ts";
import {
  toQueryResult,
  withoutFields,
  withPaging
} from "@libs/api/utils/paging.utils.ts";
import {
  AddUserAction,
  CreatePdfArgs,
  InboxDocumentDto,
  InboxDocumentSearchArgs,
  InboxStateDto,
  MoveToClinicalRecordArgsDto,
  RefInstructionsDto,
  RefOutcomesDto,
  RefPriorityTypeDto,
  RefStoreInDestinationDto,
  RefUserActionTypeDto,
  RefUserTaskStatusDto,
  StagingConfigDto,
  TaskSearchArgsDto,
  UpdateInboxDocumentDto,
  UpdateUserAction,
  UploadBatchArgsDto,
  UserActionDto,
  UserTaskDto
} from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";

/**
 * Gateway class that is used to communicate with Inbox Document backend API
 */
export class InboxDocumentsGateway implements IInboxDocumentsGateway {
  constructor(private api: AxiosInstance) {}

  getSaSUri(): Promise<StagingConfigDto> {
    return this.api
      .get<StagingConfigDto>("/inboxdocuments/sasUri")
      .then(x => x.data);
  }

  async getInboxDocuments(
    request: InboxDocumentSearchArgs
  ): Promise<QueryResult<InboxDocumentDto>> {
    const queryString = withPaging(request);
    return this.api
      .get<InboxDocumentDto[]>(`/inboxdocuments?${stringify(queryString)}`)
      .then(x => toQueryResult(x));
  }

  async getUserInboxDocuments(
    request: PagingOptions
  ): Promise<QueryResult<InboxDocumentDto>> {
    const queryString = withPaging(request);
    return this.api
      .get<InboxDocumentDto[]>(`/inboxdocuments/me?${stringify(queryString)}`)
      .then(x => toQueryResult(x));
  }

  getUserTasks(
    request: TaskSearchArgsDto & PagingOptions
  ): Promise<QueryResult<UserTaskDto>> {
    const queryString = withPaging(request);
    return this.api
      .get<UserTaskDto[]>(`usertasks?${stringify(queryString)}`)
      .then(x => toQueryResult(x));
  }

  async getUserTask(id: string): Promise<UserTaskDto> {
    return this.api.get<UserTaskDto>(`/usertasks/${id}`).then(x => x.data);
  }

  async updateUserTask(data: UserTaskDto): Promise<UserTaskDto> {
    return this.api
      .put<UserTaskDto>(`/usertasks/${data.id}`, data)
      .then(x => x.data);
  }

  getInboxDocument(
    id: string,
    documentDetailId: string
  ): Promise<InboxDocumentDto> {
    return this.api
      .get<InboxDocumentDto>(`/inboxdocuments/${id}/${documentDetailId}`)
      .then(x => x.data);
  }

  upload(data: UploadBatchArgsDto): Promise<void> {
    return this.api.post("/inboxdocuments/uploadbatch", data).then(x => x.data);
  }

  updateAssignInboxDocument(
    data: UpdateInboxDocumentDto
  ): Promise<InboxDocumentDto> {
    return this.api
      .put<InboxDocumentDto>(`inboxdocuments/${data.id}/$movetouserinbox`, data)
      .then(x => x.data);
  }

  updateAssignUserInboxDocument(
    data: UpdateInboxDocumentDto
  ): Promise<InboxDocumentDto> {
    return this.api
      .put<InboxDocumentDto>("inboxdocuments/me", data)
      .then(x => x.data);
  }

  updateStoreInboxDocument(
    data: MoveToClinicalRecordArgsDto
  ): Promise<InboxDocumentDto> {
    return this.api
      .put<InboxDocumentDto>(
        `inboxdocuments/${data.inboxDocument.id}/$movetoclinicalrecord`,
        data
      )
      .then(x => x.data);
  }

  createPdf(args: CreatePdfArgs): Promise<string> {
    return this.api
      .post<string>("/inboxdocuments/createPdf", args)
      .then(x => x.data);
  }

  getStoreInDestinations() {
    return this.api
      .get<RefStoreInDestinationDto[]>("/ref/storageDestinations")
      .then(x => x.data);
  }

  getUserActionTypes() {
    return this.api
      .get<RefUserActionTypeDto[]>("/ref/userActionTypes")
      .then(x => x.data);
  }

  getPriorityTypes() {
    return this.api
      .get<RefPriorityTypeDto[]>("/ref/priorityTypes")
      .then(x => x.data);
  }

  getInstructions() {
    return this.api
      .get<RefInstructionsDto[]>("/ref/instructions")
      .then(x => x.data);
  }

  getOutcomes() {
    return this.api
      .get<RefInstructionsDto[]>("/ref/outcomes")
      .then(x => x.data);
  }

  getUserTaskStatus() {
    return this.api
      .get<RefUserTaskStatusDto[]>("/ref/userTaskStatus")
      .then(x => x.data);
  }

  getReportType() {
    return this.getRef("reportType");
  }

  addUserAction(data: AddUserAction): Promise<void> {
    return this.api.post("/userAction", data).then(x => x.data);
  }

  updateUserAction(data: UpdateUserAction): Promise<UserActionDto> {
    return this.api.put("/userAction", data).then(x => x.data);
  }

  async getUserInboxState(): Promise<InboxStateDto> {
    const response = await this.api.get<InboxStateDto>("/inboxstate/get/me");
    return response.data;
  }

  async resetUserInboxState(): Promise<InboxStateDto> {
    const response = await this.api.put<InboxStateDto>(
      "/inboxstate/resetNewCount/me"
    );
    return response.data;
  }

  async addInboxStateItem(
    userId: string,
    inboxDocumentId: string
  ): Promise<InboxStateDto> {
    const response = await this.api.put<InboxStateDto>(
      `/inboxstate/addNewItem/${userId}`,
      inboxDocumentId
    );
    return response.data;
  }

  async deleteInboxDocument(
    id: string,
    documentDetailId: string
  ): Promise<void> {
    return this.api.delete(`/inboxdocuments/${id}/${documentDetailId}`);
  }

  async getFileContents(fileUri: string): Promise<string> {
    return fetch(fileUri).then(x => x.text());
  }

  private getRef<T extends RefDataDto = RefDataDto>(name: string) {
    return this.api
      .get<T[]>(`/ref/${name}?${withoutFields(excludedRefDataFields)}`)
      .then(x => x.data);
  }

  async getPatientDocument(
    patientId: string,
    documentId: string
  ): Promise<InboxDocumentDto> {
    return this.api
      .get<InboxDocumentDto>("/inboxdocuments/getFromPatient", {
        params: {
          patientId,
          documentId
        }
      })
      .then(x => x.data);
  }
}

export interface IInboxDocumentsGateway {
  getSaSUri(): Promise<StagingConfigDto>;

  getInboxDocuments(
    args: InboxDocumentSearchArgs
  ): Promise<QueryResult<InboxDocumentDto>>;

  getUserInboxDocuments(
    args: PagingOptions
  ): Promise<QueryResult<InboxDocumentDto>>;

  getUserTasks(
    args: TaskSearchArgsDto & PagingOptions
  ): Promise<QueryResult<UserTaskDto>>;

  getUserTask(id: string): Promise<UserTaskDto>;

  updateUserTask(data: UserTaskDto): Promise<UserTaskDto>;

  getInboxDocument(
    id: string,
    documentDetailId: string
  ): Promise<InboxDocumentDto>;

  updateStoreInboxDocument(
    data: MoveToClinicalRecordArgsDto
  ): Promise<InboxDocumentDto>;

  updateAssignInboxDocument(
    data: UpdateInboxDocumentDto
  ): Promise<InboxDocumentDto>;

  updateAssignUserInboxDocument(
    data: UpdateInboxDocumentDto
  ): Promise<InboxDocumentDto>;

  deleteInboxDocument(id: string, documentDetailId: string): Promise<void>;

  createPdf(args: CreatePdfArgs): Promise<string>;

  upload(data: UploadBatchArgsDto): Promise<void>;

  addUserAction(data: AddUserAction): Promise<void>;

  updateUserAction(data: UpdateUserAction): Promise<UserActionDto>;

  getStoreInDestinations(): Promise<RefStoreInDestinationDto[]>;

  getUserActionTypes(): Promise<RefUserActionTypeDto[]>;

  getPriorityTypes(): Promise<RefPriorityTypeDto[]>;

  getOutcomes(): Promise<RefOutcomesDto[]>;

  getUserTaskStatus(): Promise<RefUserTaskStatusDto[]>;

  getReportType(): Promise<RefDataDto[]>;

  getInstructions(): Promise<RefInstructionsDto[]>;

  getUserInboxState(): Promise<InboxStateDto>;

  resetUserInboxState(): Promise<InboxStateDto>;

  addInboxStateItem(
    userId: string,
    inboxDocumentId: string
  ): Promise<InboxStateDto>;

  getFileContents(fileUri: string): Promise<string>;

  getPatientDocument(
    patientId: string,
    documentId: string
  ): Promise<InboxDocumentDto>;
}
