import { ChangeLogDto } from "@libs/api/dtos/index.ts";

export interface RefDataDto<T extends string = string> {
  code: T;
  text: string;
  sortOrder?: number;
  isActive: boolean;
  changeLog?: ChangeLogDto;
  description?: string;
  anzscoCode?: string;
}

export const excludedRefDataFields = ["id", "sortOrder", "changeLog"];
