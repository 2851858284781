import { action, computed, observable, runInAction } from "mobx";

import { clone } from "@bps/utils";
import { mergeProperties } from "@libs/models/model.utils.ts";
import { withNullToUndefined } from "@libs/utils/utils.ts";

export class ModelBase<TDto extends object> {
  @computed
  get dirty() {
    if (this.predicate) {
      return this.predicate(this.originalDto, this.dto);
    }
    return !!this.originalDto;
  }

  @observable.ref
  originalDto: TDto | undefined;

  @observable.ref
  dto: TDto;
  constructor(
    dto: TDto,
    public predicate?: (originalDto: TDto | undefined, dto: TDto) => boolean
  ) {
    runInAction(() => {
      this.dto = dto;
      this.originalDto = dto;
    });
  }

  @action
  updateFromPatch(patch: Patch<TDto>) {
    if (!this.originalDto) {
      this.originalDto = this.dto;
    }

    const newDto = clone<TDto>(this.dto);
    mergeProperties(newDto, withNullToUndefined(patch) as any, { deep: true });
    this.dto = newDto;
  }

  @action
  updateFromDto(dto: TDto, overrideOriginalDto?: boolean) {
    this.dto = dto;
    if (overrideOriginalDto) {
      this.updateOriginalDtoFromDto(dto);
    } else {
      this.originalDto = undefined;
    }
  }

  @action
  updateOriginalDtoFromDto(dto: TDto) {
    this.originalDto = dto;
  }

  @action
  shallowMergeFromDto(dto: Partial<TDto>) {
    this.dto = { ...this.dto, ...dto };
  }

  @action
  undoPendingChanges() {
    if (this.originalDto) {
      this.updateFromDto(this.originalDto);
    }
  }
}

export class Model<
  TDto extends { id: string; eTag: string }
> extends ModelBase<TDto> {
  @computed
  get id() {
    return this.dto.id;
  }

  @computed
  get eTag() {
    return this.dto.eTag;
  }
}
