import { useCallback, useEffect } from "react";

import { DATE_FORMATS, DateTime } from "@bps/utils";

import { useAppTrackEvent } from "../app-insights/useAppTrackEvent.tsx";
// eslint-disable-next-line import/extensions
import { GAEvents, useGoogleAnalyticsEventTracking } from "../google/index.tsx";

/**
 * This hook notifies both Google Analytics and AppInsights about an opened dialog.
 * 1. automatically track opened dialog event if not hidden and dialogName provided
 * 2. provider trackDialog method in its return to track the even on request.
 * @param dialogName
 * @param user
 * @param hidden
 */

export const useDialogOpenedAnalytics = (
  dialogName?: string,
  user?: string,
  hidden?: boolean
) => {
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const notifyAppInsights = useCallback(
    useAppTrackEvent(GAEvents.dialogOpened),
    []
  );

  const notifyGoogleAnalytics = useGoogleAnalyticsEventTracking(
    GAEvents.dialogOpened
  );

  const trackDialog = (
    dialogName: string | undefined,
    user: string | undefined
  ) => {
    if (dialogName) {
      const data = {
        dialogName,
        time: DateTime.now().toFormat(DATE_FORMATS.DETAILED_DATE_TIME),
        user
      };

      notifyGoogleAnalytics(data);
      notifyAppInsights(data);
    }
  };

  useEffect(() => {
    if (!hidden) {
      trackDialog(dialogName, user);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden]);

  return trackDialog;
};
