import { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { DateTime } from "@bps/utils";
import {
  FormDeploymentOptionsDTO,
  FormDeploymentResultDTO,
  FormInstanceDTO,
  FormTemplateDTO,
  GetFormInstanceDTO,
  GetFormTemplateDTO
} from "@libs/gateways/forms/FormsGateway.dtos.ts";
import { IFormsGateway } from "@libs/gateways/forms/FormsGateway.interface.ts";

/**
 * Gateway class that is used to communicate with Reports backend API
 */
export class FormsGateway implements IFormsGateway {
  constructor(private api: AxiosInstance) {}

  getFormTemplates(request: GetFormTemplateDTO): Promise<FormTemplateDTO[]> {
    return this.api
      .get<FormTemplateDTO[]>(`/formTemplates/?${stringify(request)}`)
      .then(t => t.data);
  }

  getFormTemplate(templateId: string): Promise<FormTemplateDTO> {
    return this.api
      .get<FormTemplateDTO>(`/formTemplates/?id=${templateId}`)
      .then(x => x.data);
  }

  getFormInstances(request: GetFormInstanceDTO): Promise<FormInstanceDTO[]> {
    return this.api
      .get<FormInstanceDTO[]>(`/formInstances/?${stringify(request)}`)
      .then(t => t.data);
  }

  getFormInstance(id: string): Promise<FormInstanceDTO> {
    return this.api
      .get<FormInstanceDTO>(`/formInstances/${id}`)
      .then(x => x.data);
  }

  async deployForm(
    options: FormDeploymentOptionsDTO
  ): Promise<FormDeploymentResultDTO> {
    const { formTemplate, expiry, ...rest } = options;
    const result = await this.api.post<FormDeploymentResultDTO>(
      `formTemplates/${formTemplate.id}/deployment`,
      {
        expiry:
          expiry ?? DateTime.now().plus({ weeks: 1 }).endOf("day").toISO(),
        ...rest
      }
    );

    return result.data;
  }

  async updateForm(formInstance: FormInstanceDTO): Promise<FormInstanceDTO> {
    const result = await this.api.put<FormInstanceDTO>(
      `formInstances/${formInstance.id}`,
      formInstance
    );

    return result.data;
  }
}
