import { AxiosInstance, AxiosResponse } from "@bps/http-client";
import {
  FormDesignAddDTO,
  FormDesignUpdateDTO,
  FormGroupDTO
} from "@libs/gateways/form-design/FormDesignGateway.dtos.ts";
import { IFormDesignGateway } from "@libs/gateways/form-design/FormDesignGateway.interface.ts";

import { FormTemplateDTO } from "../forms/FormsGateway.dtos.ts";

export class FormDesignGateway implements IFormDesignGateway {
  constructor(private api: AxiosInstance) {}

  getFormDesigns(): Promise<FormDesignUpdateDTO[]> {
    return this.api.get<FormDesignUpdateDTO[]>("formDesign").then(d => d.data);
  }

  async publishForm(id: string): Promise<FormTemplateDTO> {
    const result = await this.api.post(`formDesign/${id}/publish`);
    return result.data;
  }

  async unPublishForm(id: string): Promise<FormTemplateDTO> {
    const result = await this.api.post(`formDesign/${id}/unpublish`);
    return result.data;
  }

  public getFormDesign(id: string): Promise<FormDesignUpdateDTO> {
    return this.api
      .get<FormDesignUpdateDTO>(`formDesign/${id}`)
      .then(d => d.data);
  }

  public setFormTypeCode(id: string, formTypeCode: string): Promise<void> {
    return this.api.post<string, void>(
      `formDesign/${id}/setFormType?formTypeCode=${formTypeCode}`
    );
  }

  public createFormDesign(
    design: FormDesignAddDTO
  ): Promise<FormDesignUpdateDTO> {
    return this.api
      .post<FormDesignAddDTO, AxiosResponse<FormDesignUpdateDTO>>(
        "formDesign",
        design
      )
      .then(d => d.data);
  }
  public deleteFormDesign(id: string): Promise<string> {
    return this.api.delete<string>(`formDesign/${id}`).then(d => d.data);
  }

  public updateFormDesign(
    design: FormDesignUpdateDTO
  ): Promise<FormDesignUpdateDTO> {
    return this.api
      .put<FormDesignUpdateDTO>(`formDesign/${design.id}`, design)
      .then(d => d.data);
  }

  public getFormDesignGroups(): Promise<FormGroupDTO[]> {
    return this.api.get<FormGroupDTO[]>("FormDesignGroup").then(d => d.data);
  }
}
