import { stringify } from "query-string";

import { AxiosInstance, AxiosResponse } from "@bps/http-client";
import {
  IReportsGateway,
  ReportEmbeddingArgs,
  ReportEmbeddingConfiguration
} from "@libs/gateways/reports/ReportGateway.interface.ts";
import {
  AddReportDefinitionDto,
  ReportDefinitionDto,
  ReportDto,
  ReportPrintDocument
} from "@libs/gateways/reports/ReportsGateway.dtos.ts";

export class ReportsGateway implements IReportsGateway {
  constructor(private api: AxiosInstance) {}

  getPresetReports(): Promise<ReportDefinitionDto[]> {
    return this.api
      .get<ReportDefinitionDto[]>("/reportDefinition/presets")
      .then(x => x.data);
  }
  getPublishedReports(): Promise<ReportDefinitionDto[]> {
    return this.api
      .get<ReportDefinitionDto[]>("/reportDefinition/published")
      .then(x => x.data);
  }

  getMyReports(): Promise<ReportDefinitionDto[]> {
    return this.api
      .get<ReportDefinitionDto[]>("/reportDefinition/userDefined")
      .then(x => x.data);
  }

  async getBaseReports(): Promise<ReportDto[]> {
    return this.api.get<ReportDto[]>("/reports").then(x => x.data);
  }

  async addUserDefinedReport(report: AddReportDefinitionDto) {
    return await this.api
      .post<AddReportDefinitionDto, AxiosResponse<ReportDefinitionDto>>(
        "/reportDefinition/userDefined",
        report
      )
      .then(x => x.data);
  }

  async updateUserDefinedReport(
    reportId: string,
    report: ReportDefinitionDto
  ): Promise<ReportDefinitionDto> {
    return await this.api
      .put<ReportDefinitionDto>(
        `/reportDefinition/userDefined/${reportId}`,
        report
      )
      .then(x => x.data);
  }

  async deleteUserDefinedReport(reportId: string) {
    await this.api.delete(`/reportDefinition/userDefined/${reportId}`);
  }
  async addPublishedReport(report: AddReportDefinitionDto) {
    return await this.api
      .post<AddReportDefinitionDto, AxiosResponse<ReportDefinitionDto>>(
        "/reportDefinition/published",
        report
      )
      .then(x => x.data);
  }

  async updatePublishedReport(
    reportId: string,
    report: ReportDefinitionDto
  ): Promise<ReportDefinitionDto> {
    return await this.api
      .put<ReportDefinitionDto>(
        `/reportDefinition/published/${reportId}`,
        report
      )
      .then(x => x.data);
  }

  async deletePublishedReport(reportId: string) {
    await this.api.delete(`/reportDefinition/published/${reportId}`);
  }

  async getEmbedConfiguration(
    args: ReportEmbeddingArgs
  ): Promise<ReportEmbeddingConfiguration> {
    return (await this.api

      .post<string>(`/embed?reportId=${args.reportId}&type=${args.type}`, args)

      .then(x => x.data)) as ReportEmbeddingConfiguration;
  }
  getSasUrl(fileStagingId: string): Promise<string> {
    return this.api
      .get(`/documentRenderResponse/getsasuri/?${stringify({ fileStagingId })}`)
      .then(x => x.data);
  }

  openReportPdf(printDocument: ReportPrintDocument): Promise<string> {
    return this.api
      .post<string>("/reports/print", printDocument)
      .then(x => x.data);
  }
}
