import { runInAction } from "mobx";

import { RefDataDto } from "./dto.ts";

export interface RefData<T extends string = string> {
  code: T;
  text: string;
  sortOrder?: number;
  disabled?: boolean;
  description?: string;
}

export function replaceRefData<Code extends string, T extends RefData<Code>>(
  map: Map<Code, T>,
  refData: T[]
) {
  runInAction(() => {
    map.clear();
    refData.forEach(x => map.set(x.code, x));
    return map;
  });
}

export type ConvertToRefData<
  TRefDataDto extends RefDataDto<T>,
  T extends string = string
> = Omit<TRefDataDto, "isActive"> & { disabled?: boolean };

/**
 * Transforms a ref data DTO into a RefData interface
 * by turning isActive property into disabled property (isActive:false => disabled:true)
 * @param from
 */
export function toRefData<
  TRefDataDto extends RefDataDto<T>,
  T extends string = string
>(from: TRefDataDto): ConvertToRefData<TRefDataDto>;
export function toRefData<
  TRefDataDto extends RefDataDto<T>,
  T extends string = string
>(from: TRefDataDto[]): ConvertToRefData<TRefDataDto>[];
export function toRefData<
  TRefDataDto extends RefDataDto<T>,
  T extends string = string
>(
  from: TRefDataDto | TRefDataDto[]
): ConvertToRefData<TRefDataDto> | ConvertToRefData<TRefDataDto>[] {
  if (Array.isArray(from)) {
    return from.map(x => toRefData(x));
  } else {
    const { isActive, ...rest } = from;
    return {
      ...rest,
      ...(!isActive && { disabled: true })
    };
  }
}
