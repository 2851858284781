import { ChangeLogDto } from "@libs/api/dtos/index.ts";

export enum NotificationType {
  System = "System",
  Comms = "Comms",
  Booking = "Booking",
  Security = "Security"
}

export enum NotificationPriority {
  Low = "Low",
  Normal = "Normal",
  High = "High"
}

export interface NotificationDto {
  id: string;
  title: string;
  description?: string | undefined;
  type: NotificationType;
  subtype?: string | undefined;
  priority: NotificationPriority;
  userId: string | undefined;
  isRead?: boolean;
  eTag: string;
  changeLog?: ChangeLogDto;
  context?: { [key: string]: string };
}

export interface PagedNotificationsDto {
  notifications: NotificationDto[];
  continuationToken?: string;
}

export interface NotificationGetRequest {
  userId?: string;
  isRead?: boolean;
  maxPerPage?: number;
  continuationToken?: string;
}

export interface NotificationUpdateRequest
  extends Pick<NotificationGetRequest, "isRead"> {}

export enum NotificationSubtype {
  AppointmentReminderFailed = "AppointmentReminderFailed",
  SecGroupAccessRequest = "SecGroupAccessRequest"
}
