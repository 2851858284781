// By default this will format a number to $1,000.00.
// If a currency of "" is provided then it will not display a dollar sign
export const currencyFormat = (
  value: number,
  options?: Partial<
    Intl.NumberFormatOptions & { currency?: "NZD" | "AUD" | "" }
  >
): string => {
  const locales = {
    NZD: "en-NZ",
    AUD: "en-AU"
  };
  // @TODO left it in the implementation just in case of possible localisation for currency. Ilya S.
  return value.toLocaleString(
    options?.currency ? locales[options.currency] : "en-AU",
    {
      ...(options || {}),
      style: options?.currency === "" ? "decimal" : "currency",
      currency: options?.currency || "AUD",
      minimumFractionDigits: options?.minimumFractionDigits ?? 2
    }
  );
};
