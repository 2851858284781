import { RefDataDto } from "@libs/api/ref-data/dto.ts";

export type DrugProductNameDto = {
  id: number;
  name: string;
  searchName: string;
  au?: number;
  nz?: number;
};

export type DrugProductPackDto = {
  id: number;
  packId: number;
  pbsDrugCode?: string;
  medication: string;
  quantity: number;
  rpts: number;
  pbsListing: PbsListingDto["code"];
  genericName: string;
  bpp?: number;
  tgp?: number;
  abbreviatedProductInformationUrl?: string;
  consumerInformationUrl?: string;
  productInformationUrl?: string;
  adminUnit?: string;
  routeId?: number;
  strength: string;
  productUnit?: string;
};

type DrugStateScheduleDto = {
  state: number;
  longName: string;
  productId: number;
  packId: number;
  schedule: number;
};

type DrugIngredientDto = {
  ingredientId: number;
  productId: number;
  ingredientName: string;
  strength: string;
  strengthUnit: string;
};

type DrugRestrictionDto = {
  restrictionId: number;
  pbsDrugCode: string;
  description: string;
};

type DrugCautionDto = {
  pbsCautionId: number;
  pbsDrugCode: string;
  description: string;
};

type DrugNoteDto = {
  pbsNoteId: number;
  pbsDrugCode: string;
  description: string;
};

export type DrugMedicationSummaryDto = {
  productPack: DrugProductPackDto;
  stateSchedule: DrugStateScheduleDto[];
  productIngredient: DrugIngredientDto[];
  pbsRestriction?: DrugRestrictionDto[];
  pbsCaution?: DrugCautionDto[];
  pbsNote?: DrugNoteDto[];
};

export type GetMedicationSummaryDto = {
  productNameId?: string;
  packId?: string;
  productId?: string;
};

export enum ReactionAgentKind {
  Ingredient = "Ingredient",
  DrugClass = "DrugClass",
  NonDrug = "NonDrug",
  Other = "Other"
}

export interface ReactionAgentTypeDto {
  id: number;
  description: string;
  type: ReactionAgentKind;
}

export interface PbsListingDto extends RefDataDto {
  longText: string;
}

export interface IngredientDto {
  id: number;
  ingredientName: string;
  breastCode: number;
  sportCode: number;
  pregnancyCode: number;
  updated: string;
  genCode: number;
  mimsPregCode: number;
}

export interface DrugClassDto {
  id: number;
  description: string;
  updated: string;
}

export interface NonDrugDto {
  code: number;
  preferredTerm: string;
  updated: string;
}

export enum PbsListing {
  NonPBS = "0",
  RPBS = "1",
  PBSORRPBS = "2",
  RPBSRB = "3",
  PBSORRPBSRB = "4",
  S100 = "5",
  S100Private = "6",
  S100Public = "7",
  S100CommunityAccess = "8",
  RPBSAuth = "9",
  RPBSAuthStreamlined = "10",
  PBSRPBSAuth = "11",
  PBSRPBSAuthStreamlined = "12"
}

export enum ScriptType {
  PBS = "PBS",
  RPBS = "RPBS",
  PRIVATE = "PRIVATE"
}
