import { AxiosInstance } from "axios";
import { stringify } from "query-string";

import {
  NotificationDto,
  NotificationGetRequest,
  NotificationUpdateRequest,
  PagedNotificationsDto
} from "@libs/gateways/notification/NotificationGateway.dtos.ts";
import { INotificationGateway } from "@libs/gateways/notification/NotificationGateway.interface.ts";

export class NotificationGateway implements INotificationGateway {
  constructor(private api: AxiosInstance) {}
  async getNotifications(
    request: NotificationGetRequest
  ): Promise<PagedNotificationsDto> {
    const response = await this.api.get(`/notifications?${stringify(request)}`);

    return response.data;
  }

  async getNotification(id: string, userId: string): Promise<NotificationDto> {
    return await this.api
      .get<NotificationDto>(`notifications/${userId}/${id}/`)
      .then(x => x.data);
  }
  async getNotificationsCount(
    userId: string,
    isRead: boolean
  ): Promise<number> {
    return await this.api
      .get<number>(`notifications/${userId}/count?isRead=${isRead}`)
      .then(x => x.data);
  }

  async updateNotification(
    notificationId: string,
    userId: string,
    request: NotificationUpdateRequest
  ): Promise<NotificationDto> {
    return this.api
      .put<NotificationDto>(
        `notifications/${userId}/${notificationId}`,
        request
      )
      .then(x => x.data);
  }

  async updateBatchNotifications(
    notifications: NotificationDto[]
  ): Promise<NotificationDto[]> {
    return await this.api
      .post("notifications/$updateBatch", notifications)
      .then(x => x.data);
  }

  markAllRead(userId: string): Promise<number> {
    return this.api
      .post<number>(`notifications/${userId}/$markAllRead`)
      .then(x => x.data);
  }
}
