export const notificationMessages = {
  allocationMoreThanUnallocatedPaymentAmount:
    "Allocation more than unallocated payment amount",
  unallocatedPaymentAmount: "Unallocated payment amount",

  pdfCannotBeOpened: "Unallocated payment amount",
  pdfIsNotCreated: "PDF is not created",
  pdfIsBeingGenerated: "PDF is being generated",
  emailIsBeingGenerated: "Email is being generated",
  formIsBeingPrinted: "Form is being generated",
  pdfIsDownloaded: "PDF is downloaded",
  pdfTimeout: "PDF generation timed out",
  excelIsBeingGenerated: "Excel is being generated",
  dataFileIsBeingGenerated: (data: string) => `${data} is being generated`,
  invoiceIsBeingSent: "Invoice is being sent",
  invoiceRetrievingDetails: "An error occurred retrieving the invoice details",
  encountersRetrievingDetails: "Unallocated payment amount",
  invoiceCanceled: (data: string) =>
    `Success, invoice ${data} has been cancelled`,
  invoiceAdjusted: (data: string) =>
    `Success, invoice ${data} has been adjusted`,
  invoiceSaved: (data: string) => `Success, Invoice ${data} has been saved`,
  invoiceItems: "Invoice should have at least one item",
  allocationSaved: (data: string) =>
    `Success, allocation ${data} has been saved`,
  allocationCancelled: (data: string) =>
    `Success, allocation ${data} has been cancelled`,

  allocationAdjusted: (data: string) =>
    `Success, allocation ${data} has been adjusted`,
  invoiceSettingsSaved: "Success, invoice settings has been saved",
  invoiceSettingsUpdated: "Success, invoice settings has been updated",
  scheduleAdded: (data: string) => `Success, schedule ${data} has been saved`,
  scheduleUpdated: (data: string) =>
    `Success, schedule ${data} has been updated`,
  scheduleDeleted: (data: string) =>
    `Success, schedule ${data} has been deleted`,
  serviceAdded: (data: string) => `Success, service ${data} has been saved`,
  serviceUpdated: (data: string) => `Success, service ${data} has been updated`,
  contactUpdated: (data: string) => `Success, ${data} has been updated`,
  claimDeleted: "Success, 1 claim has been deleted",
  claimsDeleted: (data: string) => `Success, ${data} claims have been deleted`,
  claimsLodged: "Success, claim has been lodged",
  claimAdjustmentDeleted: (data: string) =>
    `Success, ACC32 ${data} has been deleted`,
  selectAppt: "Select a appointment to edit",
  documentSent: "Document has been sent for upload",
  commScheduleDeleted: "Schedule has been deleted",
  commScheduleQueued: "Schedule has been queued to run",
  commTemplateDeleted: "Template has been deleted",
  emailIsBeingSent: "Email is being sent",
  purchaseOrderDeleted: (data: string) =>
    `Success, purchase order ${data} has been deleted`,
  apiKeyCopied: "API Key has been copied",
  apiKeyDeleted: "API Key has been deleted",
  apiKeyUpdated:
    "New API key created. Please update key for provider in Physitrack account settings.",
  duplicateContact: "This contact method is a duplicate. Please discard.",
  cancelWriteOff: (data: string) =>
    `Success, write-off ${data} has been cancelled`,
  addWriteOff: (data: string) => `Success, Write-off ${data} has been created`,
  addRefund: (data: string) => `Success, Refund ${data} has been created`,
  refundCancelled: (data: string) =>
    `Success, refund ${data} has been cancelled`,
  addCreditNote: (data: string) => `Success, Credit ${data} has been created`,
  addAllocation: (data: string) =>
    `Success, allocation ${data} has been created`,
  addPayment: (data: string) => `Success, Payment ${data} has been created`,
  paymentCancelled: (data: string) =>
    `Success, payment ${data} has been cancelled`,

  draftItemsSaved: "Success, draft item has been saved",
  draftItemDeleted: "Success, draft items has been deleted",
  statementDeleted: "Statement has been deleted",
  draftItemFinalised: "Success, draft item has been finalised"
};
