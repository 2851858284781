export enum Entity {
  User = "Core.User",
  Contact = "PracticeManagement.Contact",
  BookingTenantSettings = "Booking.TenantSetting",
  AppointmentType = "Booking.AppointmentType",
  CalendarEvent = "Booking.CalendarEvent",
  OrgUnitAvailability = "Booking.OrgUnitAvailability",
  UserAvailability = "Booking.UserAvailability",
  Interaction = "Clinical.Interaction",
  Invoice = "Billing.Invoice",
  Statement = "Billing.Statement",
  InvoiceDocument = "Billing.DocumentRenderResponse",
  ReportDocument = "Reports.DocumentRenderResponse",
  ReportDefinition = "Reports.ReportDefinition",
  FormInstance = "Forms.FormInstance",
  FormTemplate = "Forms.FormTemplate",
  FormDesign = "Forms.FormDesign",
  Encounter = "Clinical.Encounter",
  Amendment = "Clinical.Amendment",
  ClinicalDocument = "Clinical.Document",
  EpisodeOfCare = "Clinical.EpisodeOfCare",
  CorrespondenceMetaDataUploaded = "Clinical.CorrespondenceMetaDataUploaded",
  CalendarEventRecurrence = "Booking.CalendarEventRecurrence",
  ClinicalRecordCorrespondence = "Clinical.ClinicalRecordCorrespondence",
  DocumentMerge = "Clinical.DocumentMerge",
  WaitingList = "Booking.WaitingList",
  CalendarEventExtension = "UserExperience.CalendarEventExtension",
  DocumentWorkflowInboxDocument = "DocumentWorkflow.InboxDocumentCommitted",
  DocumentWorkflowInboxDocumentUpdate = "DocumentWorkflow.InboxDocument",
  DocumentWorkflowInboxStateAddItem = "DocumentWorkflow.InboxState",
  Provider = "PracticeManagement.Provider",
  AccClaim = "Acc.Claim",
  AccClaimAdjustment = "Acc.ClaimAdjustment",
  AccClaimReview = "Acc.ClaimReview",
  AccPurchaseOrder = "Acc.PurchaseOrder",
  AccClaimAppointment = "Acc.ClaimAppointment",
  PatientNotice = "PracticeManagement.PatientNotice",
  UserStorage = "UserExperience.UserStorage",
  AppointmentReminderJob = "Booking.AppointmentReminderJob",
  OutboundCommTemplate = "Comms.OutboundCommTemplate",
  UserTask = "DocumentWorkflow.UserTask",
  Service = "Billing.Service",
  Notification = "Notifications.Notification",
  OrgUnit = "PracticeManagement.OrgUnit",
  Transaction = "Billing.Transaction",
  TenantSetting = "UserExperience.TenantSetting",
  UserSetting = "UserExperience.UserSetting",
  OrgUnitSetting = "UserExperience.OrgUnitSetting",
  CalendarEventReminderReply = "Booking.CalendarEventReminderReply",
  InvoiceItemDelete = "Billing.InvoiceItem",
  PhysitrackApiKeyUpdate = "Physitrack.Key",
  SecGroupAccessRequest = "Core.SecGroupAccessRequest",
  CoreOrgUnit = "Core.OrgUnit",
  TemplateManagementTemplate = "TemplateManagement.Template",
  EncounterTimer = "Clinical.EncounterTimer",
  Template = "Document.Template",
  QuickAccess = "UserExperience.QuickAccess"
}
