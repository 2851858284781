import { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { ICustomIntegrationsGateway } from "@libs/gateways/custom-inegration/CustomIntegration.interface.ts";
import {
  PhysitrackApiKeyDto,
  SearchPhysitrackApiKeyDto
} from "@libs/gateways/custom-inegration/CustomIntegrationGateway.dtos.ts";

export class CustomIntegrationsGateway implements ICustomIntegrationsGateway {
  constructor(private api: AxiosInstance) {}

  public async addPhysitrackApiKey(
    providerId: string
  ): Promise<PhysitrackApiKeyDto> {
    const requestData = {
      providerId
    };

    const response = await this.api.post<PhysitrackApiKeyDto>(
      "/physitrack/keys",
      requestData
    );
    return response.data;
  }

  public deletePhysitrackApiKey(id: string): Promise<void> {
    return this.api.delete(`/physitrack/keys/${id}`);
  }

  public async regeneratePhysitrackApiKey(
    id: string
  ): Promise<PhysitrackApiKeyDto> {
    const response = await this.api.post<PhysitrackApiKeyDto>(
      `/physitrack/keys/${id}/regeneratekey`
    );
    return response.data;
  }

  public async getPhysitrackApiKeys(): Promise<PhysitrackApiKeyDto[]> {
    const response =
      await this.api.get<PhysitrackApiKeyDto[]>("/physitrack/keys");
    return response.data;
  }

  public async getPhysitrackApiKeyById(
    id: string
  ): Promise<PhysitrackApiKeyDto> {
    const response = await this.api.get<PhysitrackApiKeyDto>(
      `/physitrack/keys/${id}`
    );
    return response.data;
  }

  public async searchPhysitrackApiKey(
    request: SearchPhysitrackApiKeyDto
  ): Promise<PhysitrackApiKeyDto[]> {
    const response = await this.api.get<PhysitrackApiKeyDto[]>(
      `/physitrack/keys/search?${stringify(request)}`
    );
    return response.data;
  }

  public emailPhysitrackApiKey(id: string): Promise<void> {
    return this.api.post(`/physitrack/keys/${id}/emailApiKey`);
  }
}
