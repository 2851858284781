/**
 * Method tp get and convert blob storage file to base64
 */
export const getBlobInBase64 = async (url: string) => {
  const res = await (await fetch(url)).blob();
  return await toBase64(res, true);
};

/**
 * Converts File | Blob into base64
 * @param file
 * @param keepPrefix
 */
export const toBase64 = (
  file: File | Blob,
  keepPrefix?: boolean
): Promise<string | undefined> =>
  new Promise<string | undefined>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const result = keepPrefix
        ? reader.result?.toString()
        : reader.result?.toString().split(",")[1];
      resolve(result ? result : undefined);
    };
    reader.onerror = error => reject(error);
  });
