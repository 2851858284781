// eslint-disable-next-line import/extensions
import isEmpty from "lodash/isEmpty";

export const isDeepEmptyUtils = (input: any) => {
  if (isEmpty(input)) {
    return true;
  }
  if (typeof input === "object") {
    for (const item of Object.values(input)) {
      if (
        (item !== undefined && typeof item !== "object") ||
        !isDeepEmptyUtils(item)
      ) {
        return false;
      }
    }
    return true;
  }

  return isEmpty(input);
};
