import {
  useAppInsightsContext,
  useTrackEvent
} from "@microsoft/applicationinsights-react-js";

/**
 * This hook pass a custom metrics (any user event) to AppInsights. See more information https://docs.microsoft.com/en-nz/azure/azure-monitor/app/javascript-react-plugin#usetrackevent
 * @param eventName
 * @param eventData
 * @param skipFirstRun
 */
export const useAppTrackEvent = (
  eventName: string,
  eventData?: object,
  skipFirstRun?: boolean
) => {
  const appInsights = useAppInsightsContext();
  const setData = useTrackEvent<object>(
    appInsights,
    eventName,
    eventData ?? {},
    skipFirstRun ?? true
  );
  return (eventData: object) => {
    return setData(eventData);
  };
};
