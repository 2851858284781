import { isEqual as lodashIsEqual } from "lodash";

import { DateTime } from "@bps/utils";
import { getOrThrow, nullifyAnyUndefined } from "@libs/utils/utils.ts";

/**
 * Similar to Object.assign with the following differences:
 * - if the target value is an array and that the value to merge is false, it will set it to empty array
 * - when the option.deep is true, it will recursively merge property objects instead of replacing them.
 * - when the option.keys is provided, only the given keys will be assigned.
 *
 * Don't use deep with cyclic references as the method doesn't protect against them.
 *
 * @param source
 * @param changes
 * @param options
 */
export function mergeProperties<TModel extends {}, TKeys extends keyof TModel>(
  source: TModel,
  changes: DeepPartialObjects<Pick<TModel, TKeys>>,
  options: {
    deep?: boolean;
    keys?: TKeys[] | undefined;
  } = { deep: false }
): void {
  const { deep } = options;
  const keys = options.keys || (Object.keys(changes) as TKeys[]);

  keys.forEach(key => {
    const currentVal: any = source[key];
    const newVal: any = changes[key];

    if (Array.isArray(currentVal)) {
      source[key] = newVal || [];
    } else if (
      deep &&
      !Array.isArray(currentVal) &&
      !Array.isArray(newVal) &&
      typeof newVal === "object" &&
      typeof currentVal === "object" &&
      !(newVal instanceof DateTime) &&
      !(currentVal instanceof DateTime)
    ) {
      mergeProperties(currentVal, newVal, { deep });
    } else {
      source[key] = newVal;
    }
  });
}

/**
 * Creates a JSON merge object according to https://tools.ietf.org/html/rfc7386
 * by comparing source and target representations
 * @param source
 * @param target
 * @param isEqual Equality comparison used to compare property values. Defaults to compare their JSON representation.
 */
export function diff<T extends {}>(
  source: T,
  target: T,
  isEqual = lodashIsEqual
): Patch<T> {
  const result: any = {};
  Object.keys(target).forEach(key => {
    const currentVal = source[key];
    const newVal = target[key];

    if (
      typeof newVal !== "object" ||
      typeof currentVal !== "object" ||
      Array.isArray(currentVal)
    ) {
      if (!isEqual(newVal, currentVal)) {
        result[key] = newVal === undefined ? null : newVal;
      }
      return;
    }

    const keyResult = diff(currentVal, newVal);
    if (Object.keys(keyResult).length > 0) {
      result[key] = keyResult;
    }
  });
  return result;
}

/**
 * Sends a PATCH request for a given model
 * The model is expected to be present in the map keyed by id.
 * On success, the model is updated with the returned dto.
 * @param request the patch request with the id
 * Any undefined field will be turned into null to clear it. If a field is not meant to be cleared, the field shouldn't
 * be part of the request object
 * @param patchFn the update function that returns a promise resolving to a new DTO representation of the model.
 * @param config an object where modelMap is the model map keyed by id.
 *
 * Returns a promise that resolved to the patched model.
 */
export async function patchModel<
  TResource extends {
    id: string;
    eTag: string;
    updateFromDto: (dto: TDto) => void;
  },
  TDto extends object,
  TRequest extends { id: string }
>(
  { id, ...data }: TRequest,
  patchFn: (
    patch: Patch<Omit<TRequest, "id">> & { id: string; eTag: string }
  ) => Promise<TDto>,
  config: {
    modelMap: Map<string, TResource>;
  }
): Promise<TResource> {
  const model = getOrThrow(config.modelMap, id);
  const nullifiedData = nullifyAnyUndefined(data);
  const dto = await patchFn({ id, eTag: model.eTag, ...nullifiedData });
  model.updateFromDto(dto);
  return model;
}
