import { computed, observable, values } from "mobx";

import { sharePendingPromise } from "../../decorators/sharePendingPromise.ts";
import { RefDataDto } from "./dto.ts";
import {
  ConvertToRefData,
  RefData,
  replaceRefData,
  toRefData
} from "./RefData.ts";

export interface KeyTextValue<T = string> {
  text: string;
  key: T;
}

export interface TPermissions extends RefDataDto {
  permission?: string;
}

/**
 * Takes a fetch function and an optional custom transformer and wraps the
 * fetching and access to the ref data items.
 */
export class RefDataAccessor<
  T extends string = string,
  TRefDataDto extends RefDataDto<T> = RefDataDto<T>,
  TRefData extends RefData<T> = ConvertToRefData<TRefDataDto>
> {
  private fetched = false;

  constructor(
    private fetch: () => Promise<readonly TRefDataDto[]>,
    private options: {
      transformer?: (dto: TRefDataDto) => TRefData;
    } = {}
  ) {}

  map = observable.map<T, TRefData>();

  /**
   * Load the reference data into a map using the provided fetch and transformer function and returns the map.
   * If the reference data is already loaded, it will not call fetch.
   */
  @sharePendingPromise()
  async load() {
    if (!this.fetched) {
      const refData = (await this.fetch()).map(dto =>
        this.options.transformer
          ? this.options.transformer(dto)
          : toRefData(dto)
      );
      replaceRefData(this.map, refData);
      this.fetched = true;
    }
    return this.map;
  }

  /**
   * Utility method to load and return the ref data as an array of key name pairs
   */
  fetchAsKeyNameValues = async () => {
    await this.load();
    return this.keyNameValues;
  };

  /**
   * The lists of loaded reference data in an array.
   */
  @computed
  get values() {
    return values(this.map);
  }

  /**
   * Utility function to consume list of ref data as an array of key/text pairs.
   */
  @computed
  get keyTextValues(): KeyTextValue<T>[] {
    return this.values.map(({ code, text }) => ({ key: code, text }));
  }

  /**
   * Utility function to consume list of ref data as an array of key/name pairs.
   */
  @computed
  get keyNameValues() {
    return this.values.map(({ code, text }) => ({ key: code, name: text }));
  }

  /**
   * Utility function to consume list of ref data as an array of value/label pairs.
   */
  @computed
  get keyLabelValues() {
    return this.values.map(({ code, text }) => ({ value: code, label: text }));
  }

  /**
   * Utility to get refData by code.
   * @param code
   */
  get(code: T) {
    return this.map.get(code);
  }
}
