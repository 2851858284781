/**
 * Utility function to trigger a file download from a given URL
 * @param fileUrl the file URL
 * @param options fileName: when specified, the given filename will be used
 *
 * Note that this makes use of the document and needs to run in the browser.
 */
export function downloadFile(
  fileUrl: string,
  options: { fileName?: string; newTab?: boolean } = {}
) {
  const a = document.createElement("a");
  a.href = fileUrl;
  if (options.newTab) {
    a.target = "_blank";
  }
  if (options.fileName) {
    a.setAttribute("download", options.fileName);
  }
  a.click();
}

export const downloadDocumentInIFrame = (url: string) => {
  // JS Triggering multiple downloads in a page is a feature that has been removed from Chrome:
  // https://bugs.chromium.org/p/chromium/issues/detail?id=822542
  // A workaround is creating <iframe> with <a>
  const iframe = document.createElement("iframe");
  iframe.style.setProperty("display", "none", "important");
  iframe.onload = () => {
    setTimeout(() => iframe.remove());
  };
  iframe.srcdoc = `<body><script>{
            const link = document.body.appendChild(document.createElement('a'));
            link.download = '';
            link.href = JSON.parse('${JSON.stringify(url)}');
            link.dispatchEvent(new MouseEvent('click'));
          }<${"/"}script></body>`;
  document.body.appendChild(iframe);
};
export const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (error) {
    return false;
  }
  return true;
};

// Files types
export function isPreviewAndPrintSupported(extension: string) {
  return (
    isHtml(extension) ||
    isPdf(extension) ||
    isImage(extension) ||
    isOffice(extension)
  );
}

export function isHtml(extension: string) {
  return ["htm", "html", ".html"].some(i => i === extension.toLowerCase());
}

export function isPdf(extension: string) {
  return "pdf" === extension!.toLowerCase();
}

export function isImage(extension: string) {
  return ["jpg", "gif", "png"].some(i => i === extension.toLowerCase());
}

export function isHL7(extension: string) {
  return ["hl7", "oru"].some(i => i === extension.toLowerCase());
}

export function isOffice(extension: string) {
  const doc = [
    "xls",
    "xlsx",
    "ods",
    "ppt",
    "pptx",
    "odp",
    "doc",
    "docx",
    "odt",
    "fodt",
    "txt",
    "rtf"
  ];
  return doc.some(i => i === extension.toLowerCase());
}
