import { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { IF_MATCH_HEADER } from "@libs/api/api.constants.ts";
import { excludedRefDataFields, RefDataDto } from "@libs/api/ref-data/dto.ts";
import { batchRequests } from "@libs/api/utils/batching.ts";
import {
  toQueryResult,
  withoutFields,
  withPaging
} from "@libs/api/utils/paging.utils.ts";
import {
  AccountDetailsDto,
  AddOrgUnitDto,
  AddSecGroupAccessRequestDto,
  AddUserDto,
  AssignLicenceDto,
  CatalogBusinessRoleDto,
  CatalogSecurityRoleDto,
  GetLicencesDto,
  GetSecGroupMembersDto,
  GetUsersDto,
  LicenceDto,
  OrgUnitDto,
  PatchOrgUnitDto,
  PatchUserDto,
  Permission,
  RefCommunicationDto,
  RefCountryDto,
  RefTitlesDto,
  ResendArgsDto,
  SecGroupAccessRequestDto,
  SecGroupMemberDto,
  UpdateSecGroupAccessRequestStatusDto,
  UserAuthorisationDto,
  UserDto
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ICoreGateway } from "@libs/gateways/core/CoreGateway.interface.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";

/**
 * Gateway class that is used to communicate with backend API
 */
export class CoreGateway implements ICoreGateway {
  constructor(private api: AxiosInstance) {}

  async getUsers(request: GetUsersDto): Promise<UserDto[]> {
    const queryString = withPaging(request);
    const response = await this.api
      .get<UserDto[]>(`/sec/users?${stringify(queryString)}`)
      .then(x => {
        x.data = x.data.map(user => Object.assign(user));
        return x;
      });
    return response.data;
  }

  async getUser(id: string): Promise<UserDto> {
    const response = await this.api.get<UserDto>(`/sec/users/${id}`);
    return response.data;
  }

  async getUserByUserName(username: string): Promise<UserDto> {
    const response = await this.api.post<UserDto>("/sec/users/findbyusername", {
      username
    });
    return response.data;
  }

  async getUsersByIds(ids: string[]): Promise<UserDto[]> {
    return batchRequests<UserDto>(ids, batchedIds =>
      this.api
        .get<UserDto[]>(
          `/sec/users/ids?${stringify({
            ids: batchedIds
          })}`
        )
        .then(x => x.data)
    );
  }

  async addUser(data: AddUserDto): Promise<UserDto> {
    const response = await this.api.post<UserDto>("/sec/users", data);
    return response.data;
  }

  async getCurrentUser(): Promise<AccountDetailsDto> {
    return this.api.get<AccountDetailsDto>("/sec/users/me").then(x => x.data);
  }

  async checkUserPermissionsFromAPI(
    userName: string,
    permissions: Permission[]
  ): Promise<boolean> {
    return this.api
      .get<boolean>(
        `/sec/users/username/${userName}/check-permissions?${stringify({
          permissions
        })}`
      )
      .then(x => x.data);
  }

  async updateUser(data: PatchUserDto): Promise<UserDto> {
    const { id, eTag, ...rest } = data;
    const response = await this.api.patch<UserDto>(`/sec/users/${id}`, rest, {
      headers: {
        [IF_MATCH_HEADER]: eTag
      }
    });
    return response.data;
  }

  getUserAuthorisation(): Promise<UserAuthorisationDto> {
    return this.api.get("/sec/users/me/authorisation").then(x => x.data);
  }

  async updateOrgUnit(data: PatchOrgUnitDto): Promise<OrgUnitDto> {
    const { id, eTag, ...rest } = data;
    const response = await this.api.patch<OrgUnitDto>(
      `/sec/orgUnit/${id}`,
      rest,
      {
        headers: {
          [IF_MATCH_HEADER]: eTag
        }
      }
    );
    return response.data;
  }

  async addOrgUnit(data: AddOrgUnitDto) {
    const response = await this.api.post("/sec/orgUnit", data);
    return response.data;
  }

  async addSecGroupAccessRequestStatus(
    data: AddSecGroupAccessRequestDto
  ): Promise<SecGroupAccessRequestDto> {
    const response = await this.api.post<SecGroupAccessRequestDto>(
      "/sec/secGroupAccessRequest",
      data
    );
    return response.data;
  }

  async getSecGroupAccessRequest(
    id: string
  ): Promise<SecGroupAccessRequestDto> {
    const response = await this.api.get<SecGroupAccessRequestDto>(
      `/sec/secGroupAccessRequest/${id}`
    );
    return response.data;
  }

  async getSecGroupAccessRequests(request: {
    userId: string;
  }): Promise<SecGroupAccessRequestDto[]> {
    const queryString = withPaging(request);
    const response = await this.api.get<SecGroupAccessRequestDto[]>(
      `/sec/secGroupAccessRequest?${stringify(queryString)}`
    );
    return response.data;
  }

  async updateSecGroupAccessRequestStatus(
    data: UpdateSecGroupAccessRequestStatusDto
  ): Promise<void> {
    const { id, ...rest } = data;
    await this.api.post(`/sec/secGroupAccessRequest/${id}/updateStatus`, rest);
  }

  async getLicence(id: string): Promise<LicenceDto> {
    const response = await this.api.get<LicenceDto>(`/sec/licences/${id}`);
    return response.data;
  }

  async getLicences(request?: GetLicencesDto): Promise<LicenceDto[]> {
    const queryString = request ? stringify(withPaging(request)) : "";
    const response = await this.api.get<LicenceDto[]>(
      `/sec/licences?${queryString}`
    );

    return response.data;
  }

  async assignLicence(request: AssignLicenceDto): Promise<LicenceDto> {
    const queryString = stringify(withPaging(request));
    const response = await this.api.put<LicenceDto>(
      `/sec/licences/assign?${queryString}`
    );

    return response.data;
  }

  async removeLicence(id: string): Promise<LicenceDto> {
    const response = await this.api.put<LicenceDto>(
      `/sec/licences/${id}/remove`
    );

    return response.data;
  }

  getTitles() {
    return this.getRef<RefTitlesDto>("titles");
  }

  getCommunicationTypes() {
    return this.getRef<RefCommunicationDto>("contactCommsTypes");
  }

  getAustralianStates() {
    return this.getRef("addressStates");
  }

  getUserStatus() {
    return this.getRef("userStatus");
  }

  getCountries() {
    return this.getRef<RefCountryDto>("countries");
  }

  getTimeZones(): Promise<RefDataDto[]> {
    return this.getRef("timeZones");
  }

  private getRef<T extends RefDataDto = RefDataDto>(name: string) {
    return this.api
      .get<T[]>(`/ref/${name}?${withoutFields(excludedRefDataFields)}`)
      .then(x => x.data);
  }

  async getOrgUnits(request: {
    hierarchyType: string;
    includeChildren?: boolean;
  }): Promise<QueryResult<OrgUnitDto>> {
    const response = await this.api
      .get<OrgUnitDto[]>(`/sec/orgUnit?${stringify(request)}`)
      .then(x => {
        x.data = x.data.map(orgUnit => Object.assign(orgUnit));
        return x;
      });

    return toQueryResult(response);
  }

  async getOrgUnit(id: string): Promise<OrgUnitDto> {
    const response = await this.api.get<OrgUnitDto>(`/sec/orgunit/${id}`);
    return response.data;
  }

  getCatalogBusinessRoles(): Promise<CatalogBusinessRoleDto[]> {
    return this.api
      .get<CatalogBusinessRoleDto[]>("/tenant/business-roles")
      .then(x => x.data);
  }

  getCatalogSecurityRoles(): Promise<CatalogSecurityRoleDto[]> {
    return this.api
      .get<CatalogSecurityRoleDto[]>("/tenant/security-roles")
      .then(x => x.data);
  }

  getSecGroupMembers(
    request: GetSecGroupMembersDto
  ): Promise<SecGroupMemberDto[]> {
    return this.api
      .get<SecGroupMemberDto[]>(`/tenant/secGroupMembers?${stringify(request)}`)
      .then(x => x.data);
  }

  resendInvite(data: ResendArgsDto): Promise<void> {
    return this.api.post("/resend", data);
  }
}
