import { ByWeekday, RRule } from "rrule";

import { DayOfWeek } from "@bps/fluent-ui";
import {
  DATE_FORMATS,
  DateTime,
  nthDayOfMonth,
  nthLastDayOfMonth,
  ordinalNumber
} from "@bps/utils";
import { MonthYearRecurrence } from "@libs/constants/month-year-recurrence.enum.ts";
import {
  Frequency,
  ScheduleDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { UserScheduleConfigured } from "@libs/utils/calendar/calendar.constants.ts";

export const toRecurrenceOptions = (value: DateTime, frequency: number) => {
  const options = [];
  options.push({
    key: MonthYearRecurrence.MonthDay,
    text: `${ordinalNumber(value.day)} of ${
      frequency === Frequency.Month
        ? "the month"
        : value.toFormat(DATE_FORMATS.MONTH_ONLY)
    }`
  });

  const numberDayOfWeek = nthDayOfMonth(value);
  const weekOfMonth = ordinalNumber(Math.floor(value.day / 7) + 1);

  if (numberDayOfWeek <= 3) {
    options.push({
      key: MonthYearRecurrence.DayOfWeek,
      text: `${weekOfMonth} ${value.toFormat(DATE_FORMATS.DAY_NAME_LONG)} of ${
        frequency === Frequency.Month
          ? "the month"
          : value.toFormat(DATE_FORMATS.MONTH_ONLY)
      }`
    });
  }

  const numberLastDayOfWeek = nthLastDayOfMonth(value);
  if (numberLastDayOfWeek <= 3) {
    options.push({
      key: MonthYearRecurrence.LastDayOfWeek,
      text: `${
        numberLastDayOfWeek === 1 ? "" : ordinalNumber(numberLastDayOfWeek)
      } last ${value.toFormat(DATE_FORMATS.DAY_NAME_LONG)} of ${
        frequency === Frequency.Month
          ? "the month"
          : value.toFormat(DATE_FORMATS.MONTH_ONLY)
      } `.trim()
    });
  }
  return options;
};
export const toMonthYearRecurrence = (
  weekPosition: number | undefined,
  monthDayRecur: number[] | undefined
) => {
  if (weekPosition && weekPosition > 0) {
    return MonthYearRecurrence.DayOfWeek;
  }
  if (weekPosition && weekPosition < 0) {
    return MonthYearRecurrence.LastDayOfWeek;
  }
  if (monthDayRecur && monthDayRecur.length > 0) {
    return MonthYearRecurrence.MonthDay;
  }
  return undefined;
};
export const rRuleFrequency = (frequency: Frequency) => {
  let rRuleFrequency: number;
  switch (frequency) {
    case Frequency.Month: {
      rRuleFrequency = RRule.MONTHLY;
      break;
    }
    case Frequency.Year: {
      rRuleFrequency = RRule.YEARLY;
      break;
    }
    case Frequency.Week: {
      rRuleFrequency = RRule.WEEKLY;
      break;
    }
    default:
      throw new Error("Unsupported Frequency Type");
  }
  return rRuleFrequency;
};
export const rRuleDayRecur = (day: number): ByWeekday => {
  switch (Number(day)) {
    case DayOfWeek.Sunday: {
      return RRule.SU.weekday;
    }
    case DayOfWeek.Saturday: {
      return RRule.SA.weekday;
    }
    case DayOfWeek.Monday: {
      return RRule.MO.weekday;
    }
    case DayOfWeek.Tuesday: {
      return RRule.TU.weekday;
    }
    case DayOfWeek.Wednesday: {
      return RRule.WE.weekday;
    }
    case DayOfWeek.Thursday: {
      return RRule.TH.weekday;
    }
    case DayOfWeek.Friday: {
      return RRule.FR.weekday;
    }
    default:
      throw new Error("Unsupported Day Type");
  }
};
export const dayRecurrences = (
  dayRecur: number[] | undefined
): ByWeekday[] | undefined => {
  let byWeekday: ByWeekday[] | undefined;
  if (dayRecur) {
    byWeekday = [];
    dayRecur?.forEach(day => {
      byWeekday?.push(rRuleDayRecur(day));
    });
  }
  return byWeekday;
};
export const isScheduleConfigured = (
  schedules: ScheduleDto[],
  dateCheck: DateTime
): UserScheduleConfigured => {
  if (!schedules || schedules.length === 0) {
    return UserScheduleConfigured.NotConfigured;
  }

  //checking all the schedules as a provider can have multiple working hours schedules

  const hasActiveSchedule = schedules.some(element => {
    const recurrenceRule = element.recurrenceRule;

    //adding a until Date when not provided, otherwise Rrule takes today's date as until date
    const until = recurrenceRule.until
      ? DateTime.fromISO(recurrenceRule.until).toJSDate()
      : dateCheck.plus({ years: 1 }).toJSDate();

    const rRule = new RRule({
      freq: rRuleFrequency(recurrenceRule.frequency),
      count: recurrenceRule.count,
      bymonth: recurrenceRule.monthRecur,
      bymonthday: recurrenceRule.monthDayRecur,
      bysetpos: recurrenceRule.weekPosition,
      dtstart: DateTime.fromISO(element.startDate).toJSDate(),
      until,
      byweekday: dayRecurrences(recurrenceRule.dayRecur)
    });

    const dayToCheck = dateCheck.startOf("day").toJSDate();

    return rRule.after(dayToCheck, true);
  });

  if (hasActiveSchedule) {
    return UserScheduleConfigured.Configured;
  }
  return UserScheduleConfigured.Expired;
};
