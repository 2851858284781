import { parse, stringify } from "query-string";

import { AxiosResponse } from "@bps/http-client";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";

/**
 * Appends paging query string parameters to a given object litteral
 * @param options the paging options
 * @param queryString the existing query string as an object litteral
 * Returns a new query string object litteral with the provided paging options
 */
export function withPaging(queryString: { [key: string]: any }): {
  [key: string]: any;
};
export function withPaging<T extends PagingOptions>(
  queryString?: T
): string | { [key: string]: any } {
  let qs = {};

  if (typeof queryString === "string") {
    qs = parse(queryString);
  } else if (typeof queryString === "object") {
    // Adding paging to querystring with $ symbol
    const { take, skip, total, ...output } = {
      $count: queryString.total,
      $skip: queryString.skip,
      $take: queryString.take,
      ...queryString
    };
    qs = output;
  }

  return typeof queryString === "object" ? qs : stringify(qs);
}

export function toQueryResult<T>(response: AxiosResponse<T[]>): QueryResult<T> {
  const skip: string | undefined = response.headers["x-paging-skip"];
  const take: string | undefined = response.headers["x-paging-take"];
  const total: string | undefined = response.headers["x-paging-total-count"];
  return {
    results: response.data,
    skip: skip ? Number(skip) : 0,
    take: take ? Number(take) : 0,
    total: total ? Number(total) : undefined
  };
}

export function hasMore({ results, skip, take, total }: QueryResult<any>) {
  return total ? !!results.length && skip + take < total : undefined;
}

export function withoutFields(
  fieldNames: string | string[],
  queryString?: string
): string;
export function withoutFields(
  fieldNames: string | string[],
  queryString: {
    [key: string]: any;
  }
): {
  [key: string]: any;
};
export function withoutFields(
  fieldNames: string | string[],
  queryString?:
    | string
    | {
        [key: string]: any;
      }
    | undefined
):
  | string
  | {
      [key: string]: any;
    } {
  let qs = {};

  if (!fieldNames || !fieldNames.length) {
    return queryString || "";
  }

  if (typeof queryString === "string") {
    qs = parse(queryString);
  } else if (typeof queryString === "object") {
    qs = Object.assign({}, queryString);
  }

  qs["$excludeFields"] =
    typeof fieldNames === "string" ? fieldNames : fieldNames.join(",");

  return typeof queryString === "object" ? qs : stringify(qs);
}
