import { ChangeLogDto } from "@libs/api/dtos/index.ts";

export interface FormDesignAddDTO {
  name: string;
  description?: string;
  title?: string;
  contextJson?: string[];
  processingKeysJson?: Record<string, string>;
  isPublished: boolean;
  isClinical?: boolean;
  groups: FormGroupDTO[];
  changeLog?: ChangeLogDto;
  platformFormId?: string;
  previewURL?: string;
  domains?: string[];
  formTypeCode?: string;
  notificationSetting?: string;
}
export enum formDesignDomainNames {
  acc = "ACC",
  practiceManagement = "PracticeManagement",
  clinical = "Clinical",
  forms = "Forms"
}

export const formDesignDomains = [
  {
    name: formDesignDomainNames.practiceManagement,
    uxName: "Admin"
  },
  {
    name: formDesignDomainNames.acc,
    uxName: formDesignDomainNames.acc
  },
  {
    name: formDesignDomainNames.forms,
    uxName: "Custom"
  },
  {
    name: "Clinical",
    uxName: "Clinical"
  }
];

export interface FormDesignUpdateDTO extends FormDesignAddDTO {
  domainCode?: string;
  id: string;
  eTag: string;
}

export interface ClinicalFormDesign {
  name: string;
  clinicalForm: string;
  pending?: boolean;
  form?: FormDesignUpdateDTO;
}

export interface FormGroupChildrenDTO {
  property?: string;
  nodeType?: string;
  formJson?: string;
  children?: FormGroupChildrenDTO[];
  userCanToggleShow?: boolean;
  userCanToggleRequired?: boolean;
  userCanChangeLabel?: boolean;
  userCanAddChoices?: boolean;
  userCanSetMinMax?: boolean;
  userCanSetMinMaxText?: boolean;
  userCanSetMaxChoices?: boolean;
  noDataEntry?: boolean;
  hiddenInDesigner?: boolean;
  groupDependency?: string;
  allowMultipleOnSameForm?: boolean;
}
export interface FieldOptionsDto {
  name?: string;
  required?: boolean;
  label?: string;
  sortOrder?: number | string;
  hidden?: boolean;
  dropdownType?: string;
  choices?: string[];
  min?: string;
  max?: string;
  step?: string;
  minText?: string;
  middleText?: string;
  maxText?: string;
  hideNumber?: boolean;
  expression?: string;
  validators?: string;
  startWithNewLine?: boolean;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  visibleIf?: string;
  hideLabel?: boolean;
  maxChoices?: number;
}
export interface FormGroupDTO extends FormGroupChildrenDTO {
  sortOrder?: number;
  domain: string;
  isCollapsed?: boolean;
  context: string;
  eTag?: string;
  groupTitle: string;
  fieldOptions?: FieldOptionsDto[];
  groupPropertyPath: string;
}
