import { DateTime } from "@bps/utils";
import { ILogger as ISignalrLogger, LogLevel } from "@microsoft/signalr";

/**
 * Basically a copy of the internal Console logger from @microsoft/SignalR
 */
class ConsoleLogger implements ISignalrLogger {
  private readonly minimumLogLevel: LogLevel;

  constructor(minimumLogLevel: LogLevel) {
    this.minimumLogLevel = minimumLogLevel;
  }

  public log(logLevel: LogLevel, message: string): void {
    if (logLevel >= this.minimumLogLevel) {
      switch (logLevel) {
        case LogLevel.Critical:
        case LogLevel.Error:
          // eslint-disable-next-line no-console
          console.error(
            `[${DateTime.now().toISO()}] ${LogLevel[logLevel]}: ${message}`
          );
          break;
        case LogLevel.Warning:
          // eslint-disable-next-line no-console
          console.warn(
            `[${DateTime.now().toISO()}] ${LogLevel[logLevel]}: ${message}`
          );
          break;
        case LogLevel.Information:
          // eslint-disable-next-line no-console
          console.info(
            `[${DateTime.now().toISO()}] ${LogLevel[logLevel]}: ${message}`
          );
          break;
        default:
          // console.debug only goes to attached debuggers in Node, so we use console.log for Trace and Debug
          // eslint-disable-next-line no-console
          console.log(
            `[${DateTime.now().toISO()}] ${LogLevel[logLevel]}: ${message}`
          );
          break;
      }
    }
  }
}

/** A logger that does nothing when log messages are sent to it. */
class NullLogger implements ISignalrLogger {
  /** The singleton instance of the {@link @microsoft/signalr.NullLogger}. */
  public static instance: ISignalrLogger = new NullLogger();

  private constructor() {}

  /** @inheritDoc */
  public log(): void {}
}

export function createLogger(logger?: ISignalrLogger | LogLevel) {
  if (logger === undefined) {
    return new ConsoleLogger(LogLevel.Information);
  }

  if (logger === null) {
    return NullLogger.instance;
  }

  if (!!(logger as ISignalrLogger).log) {
    return logger as ISignalrLogger;
  }

  return new ConsoleLogger(logger as LogLevel);
}
