/* eslint no-template-curly-in-string: "off" */
export const ValidationConstants = {
  // Initialise alpha conversion table omitting O and I as these are not allowed
  // in HPI nos & NHI issued by MOH in NZ. A=1~Z=24
  alphaTableForNzMOH: "ABCDEFGHJKLMNPQRSTUVWXYZ".split("")
};

export enum ValidationMessages {
  formDesignRequiredFields = "Complete the required information & select at least one field to create a form",
  invalid = "Invalid",
  required = "Required",
  optionsRequired = "Unless the question and two answers are provided, the question won't be displayed in the form",
  questionRequired = "Unless the question text is provided, the question won't be displayed in the form",
  textRequired = "Unless the display text is provided, the element won't be displayed in the form",
  invalidMinMax = "Minimum number must be less than maximum",
  owing = "Must be less than $$(data)",
  invalidDate = "Invalid date",
  integer = "Must be an integer",
  cpnInvalid = "Invalid check digit",
  nhiInvalid = "Invalid Check Digit",
  futureDate = "Cannot be future date",
  futureTime = "Cannot be future time",
  yearRecurrences = "Year recurrences required",
  monthRecurrences = "Month recurrences required",
  dayRecurrences = "Day recurrences required",
  later = "Must be greater than today",
  unallocated = "$(data) unallocated",
  overallocated = "$(data) overallocated",
  email = "Email has the invalid format",
  url = "Website address has the invalid format",
  lessThan = "Must be less than $(data)",
  lessThanOrEqual = "Must be less than or equal to $(data)",
  todayOrLater = "Must be today or later",
  todayOrEarlier = "Must be today or earlier",
  uploadedOrEarlier = "Must be on or before the date uploaded",
  invalidDateFormat = "Invalid date format",
  nhiLength = "NHI must be 7 character long",
  cpnLength = "CPN must be 6 character long",
  greaterThan = "Must be greater than $(data)",
  nhiNumbers = "Characters 4-7 must be numeric",
  duplicatedServiceCode = "Code already exists",
  greaterThanOrEqual = "Must be at least $(data)",
  nhiFormat = "Characters 1-3 must be letter, 4-5 must be numeric, 6-7 either letter or numeric",
  cpnLetters = "Characters 3 - 6 must be letters",
  cpnDigits = "First two characters must be numeric",
  nhiIODisallowed = "Letters I and O are not allowed",
  cpnIODisallowed = "Letters I and O are not allowed",
  maxArrayLength = "Must not exceed $(data) item(s)",
  endDateBeforeStartDate = "End date is before start date",
  startDateAfterEndDate = "Start date is after end date",
  endDateSameOrBeforeStartDate = "End date is the same or before start date",
  endTimeLaterStartTime = "End time must be later than start time",
  requiredNumLength = "Must be $(data) digits in length",
  maxLength = "Must not exceed $(data) characters",
  requiredLength = "Must be $(data) characters in length",
  minLength = "Must be at least $(data) characters in length",
  requiredArrayLength = "Must have $(data) item(s) at least",
  afterBirthdate = "Date must be after patient's date of birth",
  feeInvoiceItems = "$(data) fee items can't be added to invoices",
  onlyOneCurrentIntake = "Only 1 current intake per type allowed",
  phoneEmailOrAddressRequired = "Phone, email or address required",
  feeEndNotAfterSchedule = "Fee must not end after schedule end date",
  expiryDateValidation = "Expiry date must not end after start date",
  allocationAmountOwing = "Allocation is greater than the owing amount",
  startsBeforePreviousInstance = "Must start after the previous change date",
  invoiceItemsRequired = "Items are required before an invoice can be saved",
  arrayNoDuplicatesAllowed = "No duplicate values allowed in this collection",
  futureDateEqualOrLess = "Date must be equal to or less than the current date",
  missingPatientSummaryPageItems = "Not all patient summary page items returned",
  nextChangeAfterCurrentChange = "Next change date must be after current change date",
  startsBeforeTodayIfNextInstanceExists = "Must not be a future date if an upcoming change exists",
  yearPriorToCurrentYear = "Historical To year must be prior to Current From year",
  maxLengthForDecimal = "Must not exceed $(data) characters in length before decimal",
  nextChangeSameOrAfterCurrentChange = "Next change date must be same or after current change date",
  accidentDateEqualOrBefore = "Date must be equal to or before than the $(data) declaration date",
  currentEarlierThanHistorical = "Current intake cannot be earlier than historical intake for the same type",
  historicalCannotOverlapWithCurrent = "Historical intake cannot overlap with current intake for the same type",
  errorsInInvoiceItems = "There are errors within the items you have selected. Please complete them before saving",
  noticeTypeIsRequired = "Notice type is required",
  noticePriorityIsRequired = "Notice priority is required",
  pleaseCheckAtLeastOneCheckbox = "Please check at least one checkbox",
  thisNoticeTypeRequiresAComment = "This notice type requires a comment",
  onlyOnePrimary = "Only one account must be the primary account",
  moreThanOnce = "This account has been added more than once",
  noDuplicateActivitiesAllowed = "Duplicate activities are not allowed",
  invalidFeeTypeForMaterial = "Materials must have a flat rate or per unit fee type",
  notAbleToCompleteLockedTasks = "Locked clinical task can not be completed",
  notAbleToCompleteLockedActivities = "Locked clinical activity can not be completed",
  alreadyExists = "Already exists",
  consultNumberMustBeGreaterThan0 = "Consult number must be greater than 0",
  consultNumberMustBeLessThan151 = "Consult number must be less than 151",
  accFeesRequireClaim = "A claim must be linked before ACC items can be added",
  noDuplicateClinicalTasksAllowed = "Existing clinical task found with the same details",
  noDuplicateClinicalActivitiesAllowed = "Existing clinical activity found with the same details",
  noDuplicateClinicalReminderPreferenceAllowed = "Existing clinical reminder preference found with the same reason",
  noDateBefore1972 = "Date cannot be before 1972",
  quantityMustBeMultipleOfIncrement = "Quantity must be a multiple of $(data)",
  dateLaterThanDateOfInjury = "Must be equal to or later than the date of injury",
  dateIsBeforeGoalStart = "Must be after goal start date",
  exceedsMaximumFeeAmount = "Exceeds maximum fee amount",
  treatmentRequired = "Please select a treatment",
  fieldMustBeBlank = "The input field must be blank",
  dateMustBeToday = "The date must be today",
  userMustBeSameAsRecord = "The addressed users must be consistent",
  handTherapistMustBeAPhysioOrOccTherapist = "A Hand therapist must be accompanied by either Physiotherapist or Occupational therapist business role",
  atLeastOneOptionSelected = "At least one of above options must be selected",
  appointmentClientDuplicate = "Client has already been added to the appointment",
  maxParticipantsLessThanActive = "Max cannot be less than current participants",
  invoiceItemsHasMoreTheOnceSameService = "Each service may be billed once per day",
  mustStartWithPlusOrZero = "Must start with + or 0",
  minimumLengthOfX = "Must be at least X characters long",
  duplicateLinks = "Duplicate links",
  duplicatedShortcut = "The $(data) shortcut already exists",
  duplicatedShortcutOnUpdatingSharedAutofill = "The $(data) shortcut already exists as a private copy for this autofill. Please choose another shortcut combination",
  allowShortcut = "Must have letters, numbers, _ and - only",
  missingSource = "Missing source, remove or relink"
}
