import { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { excludedRefDataFields, RefDataDto } from "@libs/api/ref-data/dto.ts";
import { withoutFields } from "@libs/api/utils/paging.utils.ts";
import {
  GetTemplateDto,
  TemplateDto,
  TemplateFieldGroup,
  TemplateFieldOptions
} from "@libs/gateways/template-management/TemplateManagementGateway.dtos.ts";
import { ITemplateManagementGateway } from "@libs/gateways/template-management/TemplateMangement.interface.ts";

export class TemplateManagementGateway implements ITemplateManagementGateway {
  constructor(private api: AxiosInstance) {}

  getTemplateTypes(): Promise<RefDataDto<string>[]> {
    return this.getRef("templateTypes");
  }

  getTemplateFormats(): Promise<RefDataDto<string>[]> {
    return this.getRef("templateFormats");
  }

  private getRef<T extends RefDataDto = RefDataDto>(
    name: string,
    excludeFields?: string[],
    includeInactive: boolean = false
  ) {
    return this.api
      .get<T[]>(
        `/ref/${name}?$inactive=${includeInactive}&${withoutFields(
          excludeFields || excludedRefDataFields
        )}`
      )
      .then(x => x.data);
  }

  getTemplateFields(
    options: TemplateFieldOptions
  ): Promise<TemplateFieldGroup[]> {
    const encodedOption = btoa(JSON.stringify(options));
    return this.api
      .get<TemplateFieldGroup[]>(`/templateFields?context=${encodedOption}`)
      .then(x => x.data);
  }

  getTemplate(templateId: string): Promise<TemplateDto> {
    return this.api
      .get<TemplateDto>(`/template/${templateId}`)
      .then(x => x.data);
  }

  getTemplates(request: GetTemplateDto): Promise<TemplateDto[]> {
    return this.api
      .get<TemplateDto[]>(`/template/?${stringify(request)}`)
      .then(t => t.data);
  }

  updateTemplate(template: TemplateDto): Promise<TemplateDto> {
    return this.api
      .put<TemplateDto>(`template/${template.id}`, template)
      .then(x => x.data);
  }

  createTemplate(template: TemplateDto): Promise<TemplateDto> {
    return this.api.post<TemplateDto>("template", template).then(x => x.data);
  }

  deleteTemplate(templateId: string): Promise<void> {
    return this.api.delete(`/template/${templateId}`);
  }
}
