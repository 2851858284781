import { ChangeLogDto, PagingOptions } from "@libs/api/dtos/index.ts";
import { RefDataDto } from "@libs/api/ref-data/dto.ts";

export interface CreateInboxDocumentDto {
  id: string;
  name: string;
  extension: string;
}

export interface UpdateInboxDocumentDto extends CreateInboxDocumentDto {
  correspondenceType: string;
  patientFirstName?: string;
  checkedDateTime?: string;
  assignedToUserId: string;
  documentDetailId: string;
  patientLastName?: string;
  showOnTimeline?: boolean;
  fromContactId: string;
  documentDate?: string;
  receivedDate?: string;
  secGroupId?: string;
  checkedBy?: string;
  extraInfo?: string;
  patientId: string;
  eTag: string;
}

export interface InboxDocumentDto extends UpdateInboxDocumentDto {
  containerToken?: string;
  changeLog: ChangeLogDto;
  containerUri?: string;
  receivedDate?: string;
  blobFileUri?: string;
  blobSasUri?: string;
  eTag: string;
  headerEntityId?: string;
  headerDocumentDetailId?: string;
}

export interface UploadBatchArgsDto {
  fileStagingId: string;
  inboxDocuments: CreateInboxDocumentDto[];
}

export interface StagingConfigDto {
  token: string;
  uri: string;
  fileStagingId: string;
}

export enum AdvancedFilter {
  InboxIncoming = "InboxIncoming",
  InboxUploads = "InboxUploads"
}

export interface InboxDocumentSearchArgs extends PagingOptions {
  keyword?: string;
  assignedToUserId?: string;
  patientId?: string;
  receivedDate?: string;
  advancedFilter?: AdvancedFilter;
}

export interface TaskSearchArgsDto {
  assignedToUserIds?: string[];
  instructionCodes?: string[];
  priorities?: string[];
  dueDateTime?: string;
  checkedDateTime?: string;
  statuses?: string[];
  outcomeCodes?: string[];
  patientId?: string;
  userId?: string;
  userRoleCodes?: string[];
}

export interface TasksFilterValues
  extends Omit<TaskSearchArgsDto, "dueDateTime"> {
  dueDateTime?: Date;
}

export interface InboxTasksFilterValues
  extends Omit<TaskSearchArgsDto, "checkedDateTime"> {
  checkedDateTime?: Date;
}

export interface MoveToClinicalRecordArgsDto {
  inboxDocument: InboxDocumentDto;
  storeInDestination: string;
}

export interface CreatePdfArgs {
  entityId: string;
  documentId: string;
  entityType: DocumentEntityType;
}

export enum DocumentEntityType {
  DocumentProcessing = "DocumentProcessing",
  Patient = "Patient"
}

export interface UpdateSubmitAndStoreArgsDto {
  inboxDocument: Omit<InboxDocumentDto, "eTag" | "changeLog">;
  storeInDestination: string;
}

export interface MoveToClinicalRecordArgsDto {
  inboxDocument: InboxDocumentDto;
  storeInDestination: string;
  reportType?: string;
}
export interface NoActionUserActionContext {
  storeIn?: MoveToClinicalRecordArgsDto;
  userTask?: UserTaskDto;
  patientKey?: PatientKey;
}

export interface FormAddUserActionType
  extends Omit<MoveToClinicalRecordArgsDto, "inboxDocument"> {
  id: string;
  userActionType: string;
}

interface RefDataInboxBaseDto extends RefDataDto<string> {
  id: string;
  etag?: string;
  endDate?: string;
  startDate?: string;
}

export interface RefStoreInDestinationDto extends RefDataInboxBaseDto {}
interface FormAddUserAction {
  userActionType: string;
  context: UserActionContext;
}

export interface AddUserAction {
  userActionType: string;
  context: UserActionContext;
}

export type UserActionContextData = {
  storeIn?: MoveToClinicalRecordArgsDto;
  userTask?: UserTaskDto;
  patientKey?: PatientKey;
};

type UserActionContext =
  | NoActionUserActionContext
  | AdviseUserActionContext
  | ProviderToAdviseUserActionContext
  | ReceptionToAdviseUserActionContext
  | CloseTaskUserActionContext;

export interface UpdateUserAction extends AddUserAction {
  eTag: string;
  id: string;
}

export interface FormUpdateUserAction extends FormAddUserAction {
  eTag: string;
  id: string;
}

export interface UserActionDto {
  userActionType?: string;
  context?: UserActionContextData;
  eTag?: string;
  id?: string;
}

export interface UserTaskDto {
  userAction?: UserActionDto;
  assignedToUserId?: string;
  changeLog?: ChangeLogDto;
  closingComments?: string;
  instructionCode: string;
  userActionId?: string;
  dueDateTime?: string;
  outcome?: string;
  outcomeLog?: OutcomeDataItemDto[];
  priority?: string;
  subject?: string;
  status?: string;
  showOnTimeline?: boolean;
  secGroupId?: string;
  eTag: string;
  id: string;
  rootEntityETag: string;
}
export interface OutcomeDataItemDto {
  id: string;
  outcomeStatus?: string;
  outcomeDateTime?: string;
  createdByUser?: string;
  comment?: string;
}
export interface ReceptionTaskDto
  extends Omit<UserTaskDto, "assignedToUserId"> {}

export interface FormAddInstructionsTask
  extends Omit<UserTaskDto, "assignedToUserId"> {}

export interface AdviseUserActionContext {
  userTask: UserTaskDto;
  storeIn: MoveToClinicalRecordArgsDto;
}
interface ProviderToAdviseUserActionContext extends AdviseUserActionContext {}

interface ReceptionToAdviseUserActionContext extends AdviseUserActionContext {}

export interface CloseTaskUserActionContext {
  userTaskId: string;
  outcome: string;
  closingComments: string;
}

export interface RefUserActionTypeDto extends RefDataInboxBaseDto {}

export interface RefPriorityTypeDto extends RefDataInboxBaseDto {}

export interface RefOutcomesDto extends RefDataInboxBaseDto {}

export interface RefUserTaskStatusDto extends RefDataInboxBaseDto {}

export interface RefInstructionsDto extends RefDataInboxBaseDto {
  userActionTypeCode: string;
}

export enum UserActionType {
  NoAction = "NA",
  ProviderToAdvise = "PTA",
  ReceptionToAdvise = "RTA",
  CloseTask = "CT"
}

export enum Outcome {
  AttemptedContact = "AC",
  NoAction = "NA",
  Informed = "I",
  Booked = "B"
}

export enum UserTaskStatus {
  InProgress = "INPROGRESS",
  Completed = "COMPLETED",
  New = "NEW"
}

export enum Instructions {
  ProvBookAppointment = "PTAB",
  ProvRecall = "PTAR",
  ProvAcceptReferral = "PTAAR",
  ProvDeclineReferral = "PTADR",
  RecepBookAppointment = "RTAB",
  RecepRecall = "RTAR",
  RecepAcceptReferral = "RTAAR",
  RecepDeclineReferral = "RTADR",
  NoAction = "NA"
}

export enum InboxStoreInDestinationType {
  CorrespondenceIn = "COR",
  Investigations = "INV",
  ClinicalImages = "CIM"
}

export enum PriorityType {
  One = "O",
  Two = "TW",
  Three = "TH",
  Four = "F"
}

export enum InstructionsForReception {
  UrgentAppointment = "RTAU",
  NonUrgentAppointment = "RTANU"
}
export interface InboxStateDto {
  id: string;
  newDocumentCount: number;
}

export enum PdfErrors {
  PDF_TIMEOUT = "PDF_TIMEOUT",
  CANT_GENERATE_PDF = "CANT_GENERATE_PDF",
  ERROR_WHILE_GENERATING = "ERROR_WHILE_GENERATING_PDF"
}

interface PatientKey {
  patientId: string;
  documentId: string;
}

export enum ReportTypeKeys {
  normal = "NORMAL",
  acceptable = "ACCEPTABLE",
  unacceptable = "UNACCEPTABLE",
  unchecked = "UNCHECKED"
}
