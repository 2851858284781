export enum ErrorType {
  ValidationError = "ValidationError",
  BusinessError = "BusinessError",
  AuthorizationError = "AuthorizationError",
  NotFoundError = "NotFoundError",
  DuplicateError = "DuplicateError",
  InvalidPDFException = "InvalidPDFException",
  ErrorPDFGenerating = "ErrorPDFGenerating",
  PdfTimeout = "PdfTimeout",
  UnknownErrorException = "UnknownErrorException",
  ConflictError = "ConflictError"
}
