import { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { IF_MATCH_HEADER } from "@libs/api/api.constants.ts";
import { excludedRefDataFields, RefDataDto } from "@libs/api/ref-data/dto.ts";
import {
  toQueryResult,
  withoutFields,
  withPaging
} from "@libs/api/utils/paging.utils.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";

import {
  AccBenefitCountDto,
  AccOccupation,
  AccTerminologyDto,
  AddClaimAdjustmentDto,
  AddClaimAppointmentDto,
  AddClaimDto,
  AddClaimReviewDto,
  AddHealthCertificateDto,
  AddPurchaseOrderDto,
  BillableOrganisationDto,
  ClaimAdjustmentDto,
  ClaimAppointmentDto,
  ClaimDto,
  ClaimEncounterDto,
  ClaimEpisodeOfCareArgsDto,
  ClaimEpisodeOfCareDto,
  ClaimFormInstanceDTO,
  ClaimReviewDto,
  CreateClaimEncounterDto,
  DeleteCheckDto,
  DeleteClaimEpisodeOfCareArgs,
  DocumentStagingInfo,
  EmailAcc45SummaryArgs,
  GetAccBenefitCountsDto,
  GetAccTerminologiesArgsDto,
  GetClaimAdjustmentArgsDto,
  GetClaimAppointmentArgsDto,
  GetClaimReviewArgsDto,
  GetClaimsArgsDto,
  GetPurchaseOrderArgsDto,
  GetSchedulesArgsDto,
  HealthCertificateDto,
  PatchClaimDto,
  PatchClaimReviewDto,
  ProviderTypeDto,
  PurchaseOrderDto,
  RefClaimAdjustmentDocumentType,
  RefClaimAdjustmentStatus,
  RefClaimStatus,
  RefProviderContractTypeDto,
  ScheduleDto
} from "./AccGateway.dtos.ts";
import { IAccGateway } from "./AccGateway.interface.ts";

export class AccGateway implements IAccGateway {
  constructor(private api: AxiosInstance) {}

  public getAccBenefitCountsByArgs(
    request: GetAccBenefitCountsDto
  ): Promise<AccBenefitCountDto[]> {
    return this.api
      .get<AccBenefitCountDto[]>(`accBenefitCounts?${stringify(request)}`)
      .then(x => x.data);
  }

  public async addHealthCertificate(
    healthCertificateDto: AddHealthCertificateDto
  ): Promise<HealthCertificateDto> {
    const response = await this.api.post<HealthCertificateDto>(
      "/healthCertificate",
      healthCertificateDto
    );
    return response.data;
  }

  public async updateHealthCertificate(
    healthCertificateDto: AddHealthCertificateDto
  ): Promise<HealthCertificateDto> {
    const response = await this.api.put<HealthCertificateDto>(
      "/healthCertificate",
      healthCertificateDto
    );
    return response.data;
  }

  public async getHealthCertificate(): Promise<HealthCertificateDto> {
    const response =
      await this.api.get<HealthCertificateDto>("/healthCertificate");
    return response.data;
  }

  public async deleteHealthCertificate(id: string): Promise<void> {
    await this.api.delete<void>(`/healthCertificate/${id}`);
  }

  public async addClaim(claimDto: AddClaimDto): Promise<ClaimDto> {
    const response = await this.api.post<ClaimDto>("/claim", claimDto);
    return response.data;
  }

  public async getClaim(id: string): Promise<ClaimDto> {
    const response = await this.api.get<ClaimDto>(`/claim/${id}`);
    return response.data;
  }

  public async deleteClaims(claimIds: string[]) {
    await this.api.delete(`/claim/$batch?${stringify({ claimIds })}`);
  }

  public async getAccTerminologies(
    request: GetAccTerminologiesArgsDto
  ): Promise<QueryResult<AccTerminologyDto>> {
    const queryString = withPaging(request);
    const response = await this.api.get<AccTerminologyDto[]>(
      `terminology/$search?${stringify(queryString)}`
    );
    return toQueryResult<AccTerminologyDto>(response);
  }

  public async getClaims(
    request: GetClaimsArgsDto
  ): Promise<QueryResult<ClaimDto>> {
    const queryString = withPaging(request);
    const response = await this.api.get<ClaimDto[]>(
      `claim?${stringify(queryString)}`
    );
    return toQueryResult<ClaimDto>(response);
  }

  public async updateClaim(claimDto: ClaimDto): Promise<ClaimDto> {
    const response = await this.api.put<ClaimDto>(
      `/claim/${claimDto.id}`,
      claimDto
    );

    return response.data;
  }

  public async patchClaim(claimDto: PatchClaimDto): Promise<ClaimDto> {
    const { id, eTag, ...data } = claimDto;
    const response = await this.api.patch<ClaimDto>(`/claim/${id}`, data, {
      headers: {
        [IF_MATCH_HEADER]: eTag
      }
    });
    return { ...response.data };
  }

  public async submitAccInvoices(invoiceIds: string[]) {
    await this.api.post<void>(`/schedules/submit?${stringify({ invoiceIds })}`);
  }

  public async addClaimAdjustment(
    claimAdjustmentDto: AddClaimAdjustmentDto
  ): Promise<ClaimAdjustmentDto> {
    const response = await this.api.post<ClaimAdjustmentDto>(
      "/claimAdjustment",
      claimAdjustmentDto
    );
    return response.data;
  }

  public async getClaimAdjustment(id: string) {
    const response = await this.api.get<ClaimAdjustmentDto>(
      `/claimAdjustment/${id}`
    );
    return response.data;
  }

  public async patchClaimAdjustment(
    claimAdjustmentDto: ClaimAdjustmentDto
  ): Promise<ClaimAdjustmentDto> {
    const { id, eTag, ...data } = claimAdjustmentDto;
    const response = await this.api.patch<ClaimAdjustmentDto>(
      `/claimAdjustment/${id}`,
      data,
      {
        headers: {
          [IF_MATCH_HEADER]: eTag
        }
      }
    );
    return { ...response.data };
  }

  public async getClaimAdjustments(
    request: GetClaimAdjustmentArgsDto
  ): Promise<ClaimAdjustmentDto[]> {
    const response = await this.api.get<ClaimAdjustmentDto[]>(
      `/claimAdjustment?${stringify(request)}`
    );
    return response.data;
  }

  public async addLodgeAccClaims(claimIds: string[]) {
    await this.api.post<void>(`/claim/$lodge?${stringify({ claimIds })}`);
  }

  public async getClaimAppointmentDtos(
    request: GetClaimAppointmentArgsDto
  ): Promise<ClaimAppointmentDto[]> {
    const response = await this.api.get<ClaimAppointmentDto[]>(
      `/claimAppointment?${stringify(request)}`
    );
    return response.data;
  }

  public async getClaimAppointmentDto(
    id: string
  ): Promise<ClaimAppointmentDto> {
    const response = await this.api.get<ClaimAppointmentDto>(
      `/claimAppointment/${id}`
    );
    return response.data;
  }

  public async updateClaimAppointment(
    request: ClaimAppointmentDto
  ): Promise<ClaimAppointmentDto> {
    const response = await this.api.put<ClaimAppointmentDto>(
      `/claimAppointment/${request.id}`,
      request
    );
    return response.data;
  }

  public async lodgeClaimAdjustment(claimAdjustmentId: string) {
    await this.api.post(`/claimAdjustment/${claimAdjustmentId}/lodge`);
  }

  public async addClaimReviewDocumentRender(
    documentId: string,
    encounterId: string
  ) {
    await this.api.post(
      `/claimReview/encounters/${encounterId}/claimReviews/${documentId}/documentRender`
    );
  }

  public async deleteClaimAppointmentDto(id: string): Promise<void> {
    await this.api.delete<void>(`/claimAppointment/${id}`);
  }

  public async addClaimAppointment(
    request: AddClaimAppointmentDto
  ): Promise<ClaimAppointmentDto> {
    const response = await this.api.post<ClaimAppointmentDto>(
      "/claimAppointment",
      request
    );
    return response.data;
  }

  public async getEncounterIdsByClaimId(claimId: string): Promise<string[]> {
    const response = await this.api.get<string[]>(
      `/claim/${claimId}/encounters`
    );
    return response.data;
  }

  public async emailAcc45Summary(args: EmailAcc45SummaryArgs): Promise<void> {
    await this.api.post<void>(`/claim/emailAcc45Summary?${stringify(args)}`);
  }

  public async deleteClaimAdjustment(id: string): Promise<void> {
    await this.api.delete<void>(`/claimAdjustment/${id}`);
  }

  public async addClaimEncounter(
    request: CreateClaimEncounterDto
  ): Promise<ClaimEncounterDto> {
    const response = await this.api.post<ClaimEncounterDto>(
      "/claimEncounter",
      request
    );
    return response.data;
  }

  public async deleteClaimEncounter(
    claimId: string,
    encounterId: string
  ): Promise<void> {
    const queryString = {
      claimId,
      encounterId
    };
    await this.api.delete<void>(`/claimEncounter?${stringify(queryString)}`);
  }

  public async getClaimEpisodesOfCare(
    request: ClaimEpisodeOfCareArgsDto
  ): Promise<ClaimEpisodeOfCareDto[]> {
    const response = await this.api.get<ClaimEpisodeOfCareDto[]>(
      `/claimEpisodesOfCare?${stringify(request)}`
    );
    return response.data;
  }

  public async deleteClaimEpisodesOfCare(
    request: DeleteClaimEpisodeOfCareArgs
  ): Promise<void> {
    await this.api.delete(`/claimEpisodeOfCare?${stringify(request)}`);
  }

  public async addClaimEpisodeOfCare(
    request: Partial<ClaimEpisodeOfCareDto>
  ): Promise<ClaimEpisodeOfCareDto> {
    const response = await this.api.post<ClaimEpisodeOfCareDto>(
      "/claimEpisodeOfCare",
      request
    );
    return response.data;
  }

  public async renderClaimForm(claimId: string): Promise<DocumentStagingInfo> {
    const response = await this.api.post<DocumentStagingInfo>(
      `/claim/${claimId}/renderAcc45Form`
    );

    return response.data;
  }

  public async updateClaimEpisodeOfCare(
    request: ClaimEpisodeOfCareDto
  ): Promise<ClaimEpisodeOfCareDto> {
    const response = await this.api.put<ClaimEpisodeOfCareDto>(
      `/claimEpisodeOfCare/${request.id}`,
      request
    );
    return response.data;
  }

  public async getClaimReview(id: string): Promise<ClaimReviewDto> {
    const response = await this.api.get<ClaimReviewDto>(`/claimReview/${id}`);
    return response.data;
  }

  public async getClaimReviews(
    request: GetClaimReviewArgsDto
  ): Promise<ClaimReviewDto[]> {
    const response = await this.api.get<ClaimReviewDto[]>(
      `/claimReview?${stringify(request)}`
    );
    return response.data;
  }

  public async addPurchaseOrder(
    data: AddPurchaseOrderDto
  ): Promise<PurchaseOrderDto> {
    const response = await this.api.post<PurchaseOrderDto>(
      "/purchaseOrder",
      data
    );
    return response.data;
  }

  public async getPurchaseOrder(id: string): Promise<PurchaseOrderDto> {
    const response = await this.api.get<PurchaseOrderDto>(
      `/purchaseOrder/${id}`
    );
    return response.data;
  }

  public async getPurchaseOrders(
    request: GetPurchaseOrderArgsDto
  ): Promise<PurchaseOrderDto[]> {
    const response = await this.api.get<PurchaseOrderDto[]>(
      `/purchaseOrder?${stringify(request)}`
    );
    return response.data;
  }

  public async updatePurchaseOrder(
    request: PurchaseOrderDto
  ): Promise<PurchaseOrderDto> {
    const response = await this.api.put<PurchaseOrderDto>(
      `/purchaseOrder/${request.id}`,
      request
    );

    return response.data;
  }

  public async deletePurchaseOrder(id: string): Promise<void> {
    await this.api.delete<void>(`/purchaseOrder/${id}`);
  }

  public async purchaseOrderDeleteCheck(id: string): Promise<boolean> {
    const response = await this.api.get<boolean>(
      `/purchaseOrder/${id}/$deleteAllowedCheck`
    );
    return response.data;
  }

  public async addClaimReview(
    request: AddClaimReviewDto
  ): Promise<ClaimReviewDto> {
    const response = await this.api.post<ClaimReviewDto>(
      "/claimReview",
      request
    );
    return response.data;
  }

  public async patchClaimReview(
    claimReviewDto: PatchClaimReviewDto
  ): Promise<ClaimReviewDto> {
    const { id, eTag, ...data } = claimReviewDto;
    const response = await this.api.patch<ClaimReviewDto>(
      `/claimReview/${id}`,
      data,
      {
        headers: {
          [IF_MATCH_HEADER]: eTag
        }
      }
    );
    return { ...response.data };
  }

  addClaimFormInstance(
    data: ClaimFormInstanceDTO
  ): Promise<ClaimFormInstanceDTO> {
    return this.api
      .post<ClaimFormInstanceDTO>("/claimFormInstance", data)
      .then(x => x.data);
  }

  public async deleteClaimReview(id: string): Promise<void> {
    await this.api.delete(`/claimReview/${id}`);
  }

  public async queueClaimStatus(claimId: string): Promise<void> {
    await this.api.post(`/claim/${claimId}/queue-claim-status`);
  }

  public async deleteCheck(id: string): Promise<DeleteCheckDto> {
    const response = await this.api.get(`/claim/${id}/$deleteCheck`);
    return response.data;
  }

  public async discharge(id: string): Promise<void> {
    const response = await this.api.post(`/claim/${id}/discharge`);
    return response.data;
  }

  public async getBillableOrganisations(): Promise<BillableOrganisationDto[]> {
    const response = await this.api.get("/billableOrganisations");
    return response.data;
  }

  public getClaimStatuses() {
    return this.getRef<RefClaimStatus>("claimStatuses");
  }

  public getScheduleProcessingStatuses() {
    return this.getRef<RefDataDto>("scheduleProcessingStatuses");
  }

  public getScheduleItemPaymentStatuses() {
    return this.getRef<RefDataDto>("scheduleItemPaymentStatuses");
  }

  public getACCSexes() {
    return this.getRef<RefDataDto>("sexes");
  }

  public getClaimAdjustmentStatuses() {
    return this.getRef<RefClaimAdjustmentStatus>("claimAdjustmentStatuses");
  }

  public getAccidentLocations() {
    return this.getRef("accidentlocations", undefined, true);
  }

  public getAccidentScenes() {
    return this.getRef("accidentscenes");
  }

  public getSports() {
    return this.getRef("sportcodes");
  }

  public getLevelTwoEthnicities() {
    return this.getRef("level2ethnicities");
  }

  public getOccupations() {
    return this.getRef<AccOccupation>("occupations");
  }

  public getWorkTypes() {
    return this.getRef("worktypes");
  }

  public getEmploymentStatuses() {
    return this.getRef("employmentStatuses");
  }

  public getDiagnosisSides() {
    return this.getRef("diagnosissides");
  }

  public getDiagnosisStatuses() {
    return this.getRef("diagnosisstatus");
  }

  public getServiceItems() {
    return this.getRef("serviceItems");
  }

  public getDocumentContentTypes() {
    return this.getRef<RefClaimAdjustmentDocumentType>("documentContentType");
  }

  public getProviderContractTypes() {
    return this.getRef<RefProviderContractTypeDto>("ProviderContractTypes");
  }

  public getPracticeContractTypes() {
    return this.getRef("PracticeContractTypes");
  }

  public getProviderTypes() {
    return this.getRef<ProviderTypeDto>("providerTypes");
  }

  public getLiaisonWithProviderUndertaken() {
    return this.getRef("LiaisonWithProviderUndertaken");
  }

  public getStatementOnCausation() {
    return this.getRef("StatementOnCausation");
  }

  public getClaimReviewStatuses() {
    return this.getRef("ClaimReviewStatuses");
  }

  private getRef<T extends RefDataDto = RefDataDto>(
    name: string,
    excludeFields?: string[],
    includeInactive: boolean = false
  ) {
    return this.api
      .get<T[]>(
        `/ref/${name}?$inactive=${includeInactive}&${withoutFields(
          excludeFields || excludedRefDataFields
        )}`
      )
      .then(x => x.data);
  }

  public async queueCheckClaimStatus(id: string): Promise<void> {
    await this.api.post(`/claim/${id}/queue-claim-status`);
  }

  public async getSchedules(
    request: GetSchedulesArgsDto
  ): Promise<ScheduleDto[]> {
    const response = await this.api.get(`/schedules?${stringify(request)}`);
    return response.data;
  }
}
