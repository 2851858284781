import {
  AllocationDto,
  AllocationItemDto,
  AllocationTransactionReferenceDto,
  CreditNoteDto,
  InvoiceDto,
  InvoiceItemDto,
  InvoiceItemReferenceDto,
  ItemType,
  PaymentDto,
  RefundDto,
  SpenderItem,
  TransactionBaseDto,
  TransactionItemDto,
  TransactionReferenceDto,
  WriteOffDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";

export const isInvoiceItemReferenceDto = (
  transactionItemDto: TransactionItemDto
): transactionItemDto is InvoiceItemReferenceDto =>
  transactionItemDto.itemType === ItemType.CreditNote ||
  transactionItemDto.itemType === ItemType.Allocation ||
  transactionItemDto.itemType === ItemType.WriteOff;
export const isInvoiceItemDto = (
  transactionItemDto: TransactionItemDto
): transactionItemDto is InvoiceItemDto =>
  transactionItemDto.itemType === ItemType.Invoice;
export const isPaymentDto = (
  transactionDto: TransactionBaseDto
): transactionDto is PaymentDto => transactionDto.itemType === ItemType.Payment;
export const isCreditNoteDto = (
  transactionDto: TransactionBaseDto
): transactionDto is CreditNoteDto =>
  transactionDto.itemType === ItemType.CreditNote;
export const isAllocationDto = (
  transactionDto: TransactionBaseDto
): transactionDto is AllocationDto =>
  transactionDto.itemType === ItemType.Allocation;
export const isInvoiceDto = (
  transactionDto: TransactionBaseDto
): transactionDto is InvoiceDto => transactionDto.itemType === ItemType.Invoice;
export const isWriteOffDto = (
  transactionDto: TransactionBaseDto
): transactionDto is WriteOffDto =>
  transactionDto.itemType === ItemType.WriteOff;
export const isRefundDto = (
  transactionDto: TransactionBaseDto
): transactionDto is RefundDto => transactionDto.itemType === ItemType.Refund;
export const isAllocationItemDto = (
  transactionItemDto: TransactionItemDto
): transactionItemDto is AllocationItemDto =>
  transactionItemDto.itemType === ItemType.Allocation;
export const isAllocationSpenderItemDto = (
  transactionDto: SpenderItem<AllocationDto | RefundDto>
): transactionDto is SpenderItem<AllocationDto> =>
  transactionDto.itemType === ItemType.Allocation;
export const isRefundSpenderItemDto = (
  transactionDto: SpenderItem<AllocationDto | RefundDto>
): transactionDto is SpenderItem<AllocationDto> =>
  transactionDto.itemType === ItemType.Refund;
export const isAllocationReferenceItemDto = (
  transactionDto: TransactionReferenceDto
): transactionDto is AllocationTransactionReferenceDto =>
  transactionDto.itemType === ItemType.Allocation;
