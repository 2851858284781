import { ChangeLogDto } from "@libs/api/dtos/index.ts";

export interface FormTemplateDTO {
  id: string;
  name: string;
  templateData?: string;
  formDesignId?: string;
  code: string;
  contextJson: string[];
  changeLog?: ChangeLogDto;
  eTag: string;
  isClinical?: boolean;
}

export enum FormInstanceStatus {
  Pending = "Pending",
  Responded = "Responded",
  Cancelled = "Cancelled",
  Expired = "Expired"
}

export interface FormInstanceDTO {
  id: string;
  formTemplateId: string;
  formTemplateName: string;
  formTemplateTypeCode: FormTemplateTypeCode;
  patientId: string;
  userId: string;
  context: Record<string, string>;
  sentDate: string;
  responseDate?: string;
  responseJson?: string;
  documentId?: string;
  expiryDate: string;
  cancelled?: boolean;
  isClinical?: boolean;
  changeLog?: ChangeLogDto;
  hostingURL?: string;
  channelCode?: string;
}

export interface GetFormTemplateDTO {
  type?: FormTemplateTypeCode;
  types?: FormTemplateTypeCode[];
  context?: string[];
  name?: string;
}

export interface GetFormInstanceDTO {
  patientId: string;
  ignoreCompleted?: boolean;
}

export interface FormDeploymentResultDTO {
  hostingUrl?: string;
  id?: string;
  formInstance: FormInstanceDTO;
}

export enum FormsChannelType {
  Email = "EMAIL",
  SMS = "SMS",
  QrCode = "QRCODE"
}

export interface FormDeploymentOptionsDTO {
  formTemplate: FormTemplateDTO;
  context: Record<string, string>;
  expiry?: string;
  addressValue?: string;
  channelCode?:
    | FormsChannelType.SMS
    | FormsChannelType.Email
    | FormsChannelType.QrCode;
}

export enum FormTemplateTypeCode {
  patientDemographic = "PATDEMO",
  acc = "ACC",
  acc45Demographic = "ACCPATDEMO",
  custom = "CUSTOM"
}
