export type DecoratedFuncType = (...args: any[]) => Promise<any>;
let counter = 0;

/**
 * Decorator that can be applied on a class method or property that returns a Promise
 * When applied, the decorator will track any pending promise and any future
 * call to the function will share the same pending promise.
 *
 * This is useful for instance to avoid performing an identical HTTP GET request when
 * there is already a pending promise.
 *
 * Example:
 * ```
 * class MyGateway {
 *  @sharePendingPromise()
 *  getResource(id: string) {
 *      ...
 *  }
 * }
 *
 * ```
 *
 * The first argument will be used as a key to share the pending promise using the identity (===) function.
 * In the example above, calling it twice with different ids will not share the pending promise.
 *
 * The decorator accepts an option parameter where a function can be passed in to resolve the key when === is not adequate (i.e arrays)
 *
 * The decorator also supports function with none or multiple arguments.
 *
 *
 * ⚠️: in the case of multiple arguments, only the first argument is considered for sharing the promise result.
 *
 *
 */
export function sharePendingPromise(
  this: any,
  options: {
    keyResolver: (arg: any) => any;
  } = {
    keyResolver: x => x
  }
) {
  return (
    target: any,
    propertyKey: string,
    descriptor?: TypedPropertyDescriptor<DecoratedFuncType>
  ) => {
    const count = counter++;
    const pendingPromisesPropertyKey = `sharePendingPromise_${count}`;

    if (!descriptor) {
      const updatedDescriptor = Object.getOwnPropertyDescriptor(
        target,
        propertyKey
      );

      if (!updatedDescriptor) {
        throw Error(
          "sharePendingPromise decorator only supports methods and function properties"
        );
      }

      throw updatedDescriptor;
    }

    const originalMethod = descriptor.value!;

    descriptor.value = function (...args: any[]) {
      const key = options.keyResolver(args.length ? args[0] : undefined);
      const map: Map<any, Promise<any>> = this[pendingPromisesPropertyKey] ||
      new Map<any, Promise<any>>();

      const pendingPromise = map && map.get(key);
      if (pendingPromise) {
        return pendingPromise;
      }

      if (!this[pendingPromisesPropertyKey]) {
        this[pendingPromisesPropertyKey] = map;
      }

      const result = originalMethod.apply(this, args);
      if (result) {
        map.set(key, result);
      }

      return result.finally(() => {
        map.delete(key);
      });
    };
  };
}

export const deepEqualResolver = (x: any) => JSON.stringify(x);
