export interface BhbAppointmentTypeDto {
  id: string;
  eTag: string;
  name: string;
  duration?: string;
  onlineName?: string;
  onlineDescription?: string;
  minuteDuration?: number;
  sortOrder?: number;
  emailContent?: string;
  emailContentEnabled?: boolean;
  isAvailableExistingPatients?: boolean;
  isAvailableNewPatients?: boolean;
  newPatientDuration?: number;
  newPatientMinuteDuration?: number;
  availabilities: AppointmentTypeAvailabilityDto[];
  isCancellable?: boolean;
}

export interface BhbAppointmentTypeSortOrderDto {
  id?: string;
  sortOrder?: number;
}

export type UpdateBhbAppointmentTypeDto = PatchWithId<BhbAppointmentTypeDto>;
export type UpdateBhbLocationProviderDto = PatchWithId<BhbLocationProviderDto>;
export type UpdateBhbProviderDto = PatchWithId<BhbProviderDto>;
export interface AppointmentTypeAvailabilityDto {
  id: string;
  eTag: string;
  appointmentTypeId: string;
  locationId: string;
  providerId: string;
  startDate: Date;
  endDate: Date | null;
  startTime: number;
  endTime: number;
  recurrenceRule: RecurrenceRule;
}

export interface RecurrenceRule {
  frequency: RecurrenceRuleFrequency;
  interval: number;
  recurringDays: RecurrenceRuleRecurringDay[];
}

export enum RecurrenceRuleFrequency {
  Daily = 0,
  Weekly = 1
}

export enum RecurrenceRuleRecurringDay {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday = 4,
  Saturday = 5,
  Sunday = 6
}

export enum PatientRecordConfig {
  CreateOther = 0,
  CreateNewPatient = 1,
  Block = 2
}

export interface PatchBhbLocationDto extends Patch<BhbLocationDto> {
  id: string;
  eTag: string;
}

export interface BhbLocationDto {
  id: string;
  eTag: string;
  onlineBookingsEnabled: boolean | null;
  name: string;
  address: string;
  email: string;
  phone: string;
  locationUrl: string;
  showAvailability: ShowAvailabilityEnum | null | undefined;
  allowCancellationsEnabled?: boolean | null;
  minimumTimeToCancel: number | undefined;
  maximumAppointmentsPerDay: number | null | undefined;
  minimumTimeToBook: number;
  street1: string;
  street2: string;
  city: string;
  postCode: string;
  suburb: string;
  state: string;
  country: string;
  url?: string | null;
  termsAndConditions: string;
  policy?: string | null;
  logoUrl: string;
  timeZoneId: string;
  hasRecall: boolean;
  recallAppointmentTypeId: string | undefined;
  bookingPolicyEnabled: boolean;
  parkingAccessEnabled?: boolean;
  parkingAccess?: string | null;
  disabilityAccessEnabled?: boolean;
  disabilityAccess?: string | null;
  openingHours?: OpeningHours;
  alternativeHours?: AlternativeHours[];
  hasCancelRestrictions?: boolean;
  emergencyMessageEnabled?: boolean | null;
  emergencyMessage?: string | null;
  createNewPatientRecord: boolean;
  patientRecordConfig: PatientRecordConfig;
}

export enum ShowAvailabilityEnum {
  oneWeek = 1,
  twoWeeks = 2,
  threeWeeks = 3,
  fourWeeks = 4,
  fiveWeeks = 5,
  sixWeeks = 6,
  sevenWeeks = 7,
  eightWeeks = 8,
  nineWeeks = 9,
  tenWeeks = 10,
  elevenWeeks = 11,
  twelveWeeks = 12
}

export interface OpeningHours {
  monday: TimesSlot;
  tuesday: TimesSlot;
  wednesday: TimesSlot;
  thursday: TimesSlot;
  friday: TimesSlot;
  saturday: TimesSlot;
  sunday: TimesSlot;
}

export type TimesSlot = {
  isOpen?: boolean;
  startTime?: string;
  endTime?: string;
};

export interface AlternativeHours {
  isOpen?: boolean;
  name: string;
  date: string;
  startTime?: string;
  endTime?: string;
}

export interface BhbProviderDto {
  id: string;
  eTag: string;
  title: string;
  firstName: string;
  lastName: string;
  displayName: string;
  appointmentTypes: BhbAppointmentTypeDto[];
  language?: string[];
  areaOfInterest?: string[];
  genderIdentity?: string;
  profileDetail?: string;
  photoUrl?: string;
  providerOnlineStatus?: ProviderOnlineStatus;
}

export enum ProviderOnlineStatus {
  YES = "YES",
  NO = "NO",
  CALL = "CALL"
}

export enum ProviderOnlineStatusText {
  ShowAndBook = "Show & book",
  ShowAndCall = "Show & call",
  DontShow = "Don't show"
}

export interface BhbLocationProviderDto extends BhbProviderDto {
  locationId: string;
}
