/* eslint no-template-curly-in-string: "off" */
import { getIn } from "final-form";

import { DateTime, getDateTimeFromISOrJSDate, TIME_FORMATS } from "@bps/utils";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";

import { composeValidators } from "./composeValidators.ts";
import { messageWithData } from "./messageWithData.ts";
import { FieldValidator } from "./validation.types.ts";

export const Length = {
  comments: 2000,
  long: 250,
  medium: 100,
  short: 50
};

const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const URL_PATTERN =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;

const PHONE_PATTERN = /^[+]?[0-9()\s]+$/;

// LOGICAL AND PATTERN VALIDATOR METHODS
/**
 * Check that an input field is filled when is isNot is not specified or false
 * Check that an input field is empty when isNot = true.
 * @param message
 * @param isNot
 * @returns
 */
export const required =
  <T extends any = any>(
    message = ValidationMessages.required,
    isNot?: boolean
  ): FieldValidator<T> =>
  value => {
    if (isNot) {
      return (value instanceof Object && value.toString().trim()) ||
        (Array.isArray(value) && value.length > 0)
        ? message
        : undefined;
    }
    return !value ||
      (typeof value === "string" && !value.trim()) ||
      (Array.isArray(value) && value.length === 0)
      ? message
      : undefined;
  };

export const requiredBoolean =
  <T extends any = any>(
    message = ValidationMessages.required
  ): FieldValidator<T> =>
  value => {
    return typeof value !== "boolean" ? message : undefined;
  };

export const pattern = (
  patternArg: string | RegExp,
  message = ValidationMessages.invalid
): FieldValidator<string | number | undefined> => {
  const regex = new RegExp(patternArg);
  return value =>
    !!value && !regex.test(value.toString()) ? message : undefined;
};

export const predicate =
  <T extends any, TP extends object, TR extends any>(
    condition: (value: T, parent?: TP, root?: TR) => boolean,
    ...validators: FieldValidator[]
  ): FieldValidator<T, TP, TR> =>
  (value, parent, root) =>
    condition(value, parent, root)
      ? composeValidators(validators)(value, parent, root)
      : undefined;

export const email = (validationMessage = ValidationMessages.email) =>
  pattern(EMAIL_PATTERN, validationMessage);

export const url = (validationMessage = ValidationMessages.url) =>
  pattern(URL_PATTERN, validationMessage);

export const phone = pattern(PHONE_PATTERN, ValidationMessages.invalid);

export const maxLength =
  (max: number): FieldValidator =>
  (value: string | number) =>
    value && value.toString().length > max
      ? messageWithData(ValidationMessages.maxLength, max)
      : undefined;

export const minLength =
  (min: number): FieldValidator =>
  (value: string | number) =>
    value && value.toString().length < min
      ? messageWithData(ValidationMessages.minLength, min)
      : undefined;

export const requiredArrayNoDuplicateValues =
  (): FieldValidator => (value: string[]) =>
    value && Array.isArray(value) && new Set(value).size !== value.length
      ? ValidationMessages.arrayNoDuplicatesAllowed
      : undefined;

export const requiredArrayLength =
  (length: number): FieldValidator =>
  value =>
    value && Array.isArray(value) && value.length < length
      ? messageWithData(ValidationMessages.requiredArrayLength, length)
      : undefined;

export const maxArrayLength =
  (length: number, validationMessage?: string): FieldValidator =>
  value =>
    value && Array.isArray(value) && value.length > length
      ? validationMessage ||
        messageWithData(ValidationMessages.maxArrayLength, length)
      : undefined;

export const requiredCharactersLength =
  (length: number): FieldValidator =>
  (value: string | number) =>
    value && value.toString().length !== length
      ? messageWithData(ValidationMessages.requiredLength, length)
      : undefined;

export const integer =
  (message: string = ValidationMessages.integer): FieldValidator =>
  (value: string | number) => {
    if (!value) {
      return undefined;
    }

    return Number.isInteger(Number(value)) ? undefined : message;
  };

export const greaterThan =
  (
    min: number,
    message = messageWithData(ValidationMessages.greaterThan, min)
  ): FieldValidator =>
  value => {
    if (value === undefined) {
      return undefined;
    }

    const n = Number(value);
    return !Number.isNaN(n) && min < n ? undefined : message;
  };

export const greaterThanOrSameAs =
  (
    min: number,
    message = messageWithData(ValidationMessages.greaterThanOrEqual, min)
  ): FieldValidator =>
  value => {
    if (value === undefined) {
      return undefined;
    }

    const n = Number(value);
    return !Number.isNaN(n) && min <= n ? undefined : message;
  };

export const lessThan =
  (
    max: number,
    message = messageWithData(ValidationMessages.lessThan, max)
  ): FieldValidator =>
  value => {
    if (value === undefined) {
      return undefined;
    }

    const n = Number(value);
    return !Number.isNaN(n) && max > n ? undefined : message;
  };

export const lessThanOrSame =
  (
    max: number,
    message = messageWithData(ValidationMessages.lessThanOrEqual, max)
  ): FieldValidator =>
  value => {
    if (value === undefined) {
      return undefined;
    }

    const n = Number(value);
    return !Number.isNaN(n) && max >= n ? undefined : message;
  };

export const lessThanOrSameWithSuffix =
  (
    max: number,
    suffix: string,
    message = messageWithData(ValidationMessages.lessThan, max)
  ): FieldValidator =>
  value => {
    if (value === undefined) {
      return undefined;
    }

    const stripedSuffix = value.replace(suffix, "");

    const n = Number(stripedSuffix);
    if (Number.isNaN(n))
      throw Error("You require suffix what the value does not contain!");
    return !Number.isNaN(n) && max >= n ? undefined : message;
  };

/**
 * Check that a number is same or after a number from another field.
 * @param message
 * @param fieldName
 */ export const isNumberSameOrAfterField =
  (message: string, fieldName: string): FieldValidator =>
  (value: number, allValues) => {
    if (!value || isNaN(value)) {
      return undefined;
    }

    const otherNumber = getIn(allValues, fieldName);
    return value >= otherNumber ? undefined : message;
  };

export const maxLengthOfNonDecimalPartOfNumber =
  (
    max: number,
    intMessage = messageWithData(ValidationMessages.maxLength, max),
    decimalMessage = messageWithData(
      ValidationMessages.maxLengthForDecimal,
      max
    )
  ): FieldValidator =>
  (value: string | number | undefined) => {
    if (!value) {
      return undefined;
    }

    const valueAsString: string = value.toString();
    const index: number = valueAsString.indexOf(".");

    if (index < 0) {
      return valueAsString.length <= max ? undefined : intMessage;
    }
    return valueAsString.substr(0, index).length <= max
      ? undefined
      : decimalMessage;
  };

// DATE VALIDATOR METHODS
const findAnyValidDate = (value: string | Date, dateFormats: string[]) => {
  if (typeof value === "string") {
    return dateFormats.some(df => DateTime.fromFormat(value, df).isValid);
  } else {
    return true;
  }
};

export const validDate =
  (...dateFormats: string[]): FieldValidator =>
  (value: string | Date | undefined) =>
    !value || findAnyValidDate(value, dateFormats)
      ? undefined
      : ValidationMessages.invalid;

export const todayOrLater: FieldValidator = (
  value: Date | string | undefined
) => {
  const d =
    typeof value === "string"
      ? DateTime.fromISO(value)
      : DateTime.fromJSDate(value);
  return d?.isBeforeToday ? ValidationMessages.todayOrLater : undefined;
};

export const isNotFutureDate =
  (message = ValidationMessages.futureDate): FieldValidator =>
  (value: Date | string | undefined) => {
    if (!value) return undefined;

    const date: DateTime =
      typeof value === "string"
        ? DateTime.fromISO(value)
        : DateTime.fromJSDate(value).startOf("day");

    return DateTime.now() < date ? message : undefined;
  };

/**
 * Check that a date field is before another date.
 * @param date
 * @param message
 */
export const isDateBeforeDate =
  (date: DateTime | Date | undefined, message: string): FieldValidator =>
  value => {
    const val = DateTime.fromJSDate(value);
    const datetime =
      date instanceof DateTime ? date : DateTime.fromJSDate(date);

    return datetime && val < datetime ? undefined : message;
  };

/**
 * Check that a date field is before another date.
 * @param date
 * @param message
 */
export const isDateSameOrBeforeDate =
  (date: DateTime | Date | undefined, message: string): FieldValidator =>
  value => {
    const val = DateTime.fromJSDate(value);
    const datetime =
      date instanceof DateTime ? date : DateTime.fromJSDate(date);

    return datetime && val <= datetime ? undefined : message;
  };

/**
 * Check that a date field is after another date.
 * @param date
 * @param message
 */
export const isDateAfterDate =
  (date: DateTime | Date | undefined, message: string): FieldValidator =>
  (value: Date | string | undefined) => {
    const val =
      value &&
      (typeof value === "string"
        ? DateTime.fromISO(value)
        : DateTime.fromJSDate(value));

    const datetime =
      date instanceof DateTime ? date : DateTime.fromJSDate(date);

    return !datetime || !val || val > datetime ? undefined : message;
  };

/**
 * Check that a date field is same or after another date.
 * @param date
 * @param message
 */
export const isDateSameOrAfterDate =
  (date: DateTime | Date | undefined, message: string): FieldValidator =>
  (value: Date | string | undefined) => {
    if (!value) return undefined;

    const val =
      typeof value === "string"
        ? DateTime.fromISO(value)
        : DateTime.fromJSDate(value);

    const datetime =
      date instanceof DateTime ? date : DateTime.fromJSDate(date);

    return !datetime || !val || val >= datetime ? undefined : message;
  };

/**
 * Check that a date field is before another field date.
 * @param message
 * @param propertyName
 * @param orSame
 */
export const isDateBeforeField = (
  message: string,
  propertyName: string,
  orSame?: boolean
): FieldValidator => {
  return (value, allValues: object) => {
    if (!value) return undefined;

    const fromValuesDate = getIn(allValues, propertyName);
    const otherDate =
      fromValuesDate instanceof DateTime
        ? fromValuesDate
        : getDateTimeFromISOrJSDate(allValues[propertyName]);

    const thisDate =
      value instanceof DateTime ? value : getDateTimeFromISOrJSDate(value);

    if (orSame)
      return thisDate &&
        otherDate &&
        (thisDate < otherDate || thisDate.equals(otherDate))
        ? message
        : undefined;

    return thisDate && otherDate && thisDate < otherDate ? message : undefined;
  };
};

/**
 * Check that a date field is after another field date.
 * @param message
 * @param propertyName
 * @param orSame
 */
export const isDateAfterField = (
  message: string,
  propertyName: string,
  orSame?: boolean
): FieldValidator => {
  return (value, allValues: object) => {
    if (!value) return undefined;

    const otherDate = getDateTimeFromISOrJSDate(getIn(allValues, propertyName));

    const thisDate =
      value instanceof DateTime ? value : getDateTimeFromISOrJSDate(value);

    if (orSame)
      return thisDate &&
        otherDate &&
        (thisDate > otherDate || thisDate.equals(otherDate))
        ? message
        : undefined;

    return thisDate && otherDate && thisDate > otherDate ? message : undefined;
  };
};

// TIME VALIDATOR METHODS
export const validTime = (): FieldValidator => (value: string | undefined) =>
  !value ||
  DateTime.fromFormat(value, TIME_FORMATS.TIME_FORMAT_24).isValid ||
  DateTime.fromFormat(value, TIME_FORMATS.TIME_FORMAT_WITH_SECONDS).isValid
    ? undefined
    : ValidationMessages.invalid;

export const areTimesEqualField = (
  message: string,
  propertyName: string
): FieldValidator<string> => {
  return (val, values) => {
    const v1 = DateTime.fromParsedTimeString(val);
    const v2 = DateTime.fromParsedTimeString(values[propertyName]);

    return v1 && v2 && v1.equals(v2) ? message : undefined;
  };
};

export const isBeforeTimeField = (
  message: string,
  propertyName: string,
  orSame?: boolean
): FieldValidator<string> => {
  return (val, values) => {
    const v1 = DateTime.fromParsedTimeString(val);
    const v2 = DateTime.fromParsedTimeString(getIn(values, propertyName));
    if (orSame)
      return v1 && v2 && (v1.equals(v2) || v1 < v2) ? message : undefined;

    return v1 && v2 && v1 < v2 ? message : undefined;
  };
};

export const isAfterTimeField = (
  message: string,
  propertyName: string,
  orSame?: boolean
): FieldValidator<string> => {
  return (val, values) => {
    const v1 = DateTime.fromParsedTimeString(val);
    const v2 = DateTime.fromParsedTimeString(getIn(values, propertyName));
    if (orSame)
      return v1 && v2 && (v1.equals(v2) || v1 > v2) ? message : undefined;
    return v1 && v2 && v1 > v2 ? message : undefined;
  };
};

export const isNotFutureTime =
  (message = ValidationMessages.futureTime): FieldValidator =>
  (value: string | undefined) => {
    if (!value) return undefined;

    const now = DateTime.now();

    const dateTime = DateTime.fromParsedTimeString(value);

    return dateTime && (dateTime > now ? message : undefined);
  };
