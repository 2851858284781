export const nameof = <T>(name: keyof T & string) => name;
export const nameOfFactory =
  <T>() =>
  (name: keyof T & string) =>
    name;
/**
 * method return checked string of a field array
 * @param arrayName
 */
export const nameOfFieldArray =
  <T>(arrayName: string) =>
  (name: keyof T & string) =>
    `${arrayName}.${name}`;
