import { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { IF_MATCH_HEADER } from "@libs/api/api.constants.ts";
import { excludedRefDataFields, RefDataDto } from "@libs/api/ref-data/dto.ts";
import { batchRequests } from "@libs/api/utils/batching.ts";
import {
  toQueryResult,
  withoutFields,
  withPaging
} from "@libs/api/utils/paging.utils.ts";
import {
  AddUserStorageDto,
  CalendarEventExtensionDto,
  CampaignRenderArgsDto,
  EncounterExtensionDto,
  GetRecentPatientsDto,
  OrgUnitSettingDto,
  PatchPatientSettingDto,
  PatchUserSettingDto,
  PatientSettingDto,
  QuickAccessDto,
  QuickColoursDto,
  RecentPatientDto,
  RefBusinessRoleAbbreviationDto,
  RefPatientSummaryDto,
  TemplateExtensionDto,
  TenantSettingDto,
  UpdatePatientSettingDto,
  UpdateQuickAccessDto,
  UpdateQuickColoursDto,
  UpdateUserSettingDto,
  UserAccountVerifyPinRequestDto,
  UserSettingDto,
  UserStorageDto
} from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { IUserExperienceGateway } from "@libs/gateways/user-experience/UserExperienceGateway.interface.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { catchNotFoundError } from "@libs/utils/utils.ts";

/**
 * Gateway class that is used to communicate with User Experience backend API
 */
export class UserExperienceGateway implements IUserExperienceGateway {
  constructor(private api: AxiosInstance) {}

  async getUserSetting(id: string): Promise<UserSettingDto | undefined> {
    return this.api
      .get<UserSettingDto>(`users/${id}/settings`)
      .then(result => result.data)
      .catch(catchNotFoundError);
  }

  async getCurrentUserSetting(): Promise<UserSettingDto | undefined> {
    return this.api
      .get<UserSettingDto>("/me/settings")
      .then(result => result.data)
      .catch(catchNotFoundError);
  }

  async addUserSetting(
    value: UpdateUserSettingDto,
    userId: string
  ): Promise<UserSettingDto> {
    const response = await this.api.put<UserSettingDto>(
      `/users/${userId}/settings?`,
      value
    );
    return response.data;
  }

  async patchUserSetting(value: PatchUserSettingDto): Promise<UserSettingDto> {
    const response = await this.api.patch<UserSettingDto>(
      `/users/${value.userId}/settings?`,
      value,
      {
        headers: {
          [IF_MATCH_HEADER]: value.eTag
        }
      }
    );
    return response.data;
  }

  async addCurrentUserSetting(
    data: UpdateUserSettingDto
  ): Promise<UserSettingDto> {
    const response = await this.api
      .put<UserSettingDto>("/me/settings", data)
      .then(x => x.data);

    return response;
  }

  async getRecentPatients(
    request: GetRecentPatientsDto
  ): Promise<QueryResult<RecentPatientDto>> {
    const queryString = withPaging(request);
    return this.api
      .get<RecentPatientDto[]>(`/RecentPatients?${stringify(queryString)}`)
      .then(x => toQueryResult(x));
  }

  async getCurrentUserQuickAccess(): Promise<QuickAccessDto | undefined> {
    return this.api
      .get("/me/quickAccess")
      .then(result => result.data)
      .catch(catchNotFoundError);
  }

  async updateCurrentUserQuickAccess(
    value: UpdateQuickAccessDto
  ): Promise<QuickAccessDto> {
    const response = await this.api
      .post<QuickAccessDto>("/me/quickAccess", value)
      .then(x => x.data);
    return response;
  }

  async patchCurrentUserSetting(
    data: UpdateUserSettingDto
  ): Promise<UserSettingDto> {
    const response = await this.api
      .patch<UserSettingDto>("/me/settings", data, {
        headers: {
          [IF_MATCH_HEADER]: data.eTag
        }
      })
      .then(x => x.data);

    return response;
  }

  async getUsersSettings(): Promise<UserSettingDto[]> {
    const response = await this.api.get<any[]>("/users/settings");
    return response.data;
  }

  async getPatientsSettings(): Promise<PatientSettingDto[]> {
    const response =
      await this.api.get<PatientSettingDto[]>("/patients/settings");
    return response.data;
  }

  async getPatientSetting(
    patientId: string
  ): Promise<PatientSettingDto | undefined> {
    return this.api
      .get<PatientSettingDto>(`patients/${patientId}/settings`)
      .then(result => result.data)
      .catch(catchNotFoundError);
  }

  async updatePatientSetting(
    value: UpdatePatientSettingDto,
    patientId: string
  ): Promise<PatientSettingDto> {
    const response = await this.api.put<PatientSettingDto>(
      `patients/${patientId}/settings`,
      value
    );
    return response.data;
  }

  async patchPatientSetting(
    value: PatchPatientSettingDto
  ): Promise<PatientSettingDto> {
    const response = await this.api.patch<PatientSettingDto>(
      `/patients/${value.patientId}/settings?`,
      value,
      {
        headers: {
          [IF_MATCH_HEADER]: value.eTag
        }
      }
    );
    return response.data;
  }

  async getCampaignRender(
    id: string,
    request: CampaignRenderArgsDto
  ): Promise<string | undefined> {
    try {
      const data = (
        await this.api.post<string>(`/campaign/${id}/render`, request)
      ).data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  getPatientSummaries() {
    return this.api
      .get<RefPatientSummaryDto[]>("/ref/patientSummaries")
      .then(x => x.data);
  }

  getDockViews() {
    return this.getRef("dockView");
  }

  getQuickAccessIcons() {
    return this.getRef("quickAccessIcon");
  }

  getQuickColoursType() {
    return this.getRef("quickColoursType");
  }

  getTreeViewOptions() {
    return this.getRef("treeView");
  }

  getBusinessRoleAbbreviations() {
    return this.getRef<RefBusinessRoleAbbreviationDto>(
      "businessRoleAbbreviations"
    );
  }

  getApptTypeBaseIntervals() {
    return this.getRef("apptTypeBaseIntervals");
  }

  getRef<T extends RefDataDto = RefDataDto>(name: string) {
    return this.api
      .get<T[]>(`/ref/${name}?${withoutFields(excludedRefDataFields)}`)
      .then(x => x.data);
  }

  getTenantSetting(): Promise<TenantSettingDto> {
    return this.api
      .get<TenantSettingDto>("/tenants/settings")
      .then(x => x.data);
  }

  updateTenantSetting(data: TenantSettingDto): Promise<TenantSettingDto> {
    return this.api
      .put<TenantSettingDto>("tenants/settings", data)
      .then(x => x.data);
  }

  async getUserStorage(key: string): Promise<UserStorageDto | undefined> {
    return this.api
      .get<UserStorageDto>(`/me/storage/${key}`)
      .then(result => result.data)
      .catch(catchNotFoundError);
  }

  async updateUserStorage(
    key: string,
    value: UserStorageDto
  ): Promise<UserStorageDto> {
    const response = await this.api.put<UserStorageDto>(
      `/me/storage/${key}`,
      value
    );
    return response.data;
  }

  async addUserStorage(
    key: string,
    value: AddUserStorageDto
  ): Promise<UserStorageDto> {
    const response = await this.api.put<UserStorageDto>(
      `/me/storage/${key}`,
      value
    );
    return response.data;
  }

  async getCalendarEventExtension(
    calendarEventId: string
  ): Promise<CalendarEventExtensionDto | undefined> {
    return this.api
      .get<CalendarEventExtensionDto>(
        `calendarEvent/extensions/${calendarEventId}`
      )
      .then(result => result.data)
      .catch(catchNotFoundError);
  }

  getCalendarEventExtensions(
    calendarEventIds: string[]
  ): Promise<CalendarEventExtensionDto[]> {
    return batchRequests<CalendarEventExtensionDto>(calendarEventIds, ids =>
      this.api
        .get<CalendarEventExtensionDto[]>(
          `calendarEvent/extensions?${stringify({ calendarEventIds: ids })}`
        )
        .then(x => x.data)
    );
  }

  getUserSettingsByUserIds(userIds: string[]): Promise<UserSettingDto[]> {
    return batchRequests<UserSettingDto>(userIds, ids =>
      this.api
        .get<any[]>(`/users/settings?${stringify({ userIds: ids })}`)
        .then(respense => respense.data)
    );
  }

  async getOrgUnitSetting(orgUnitId: string): Promise<OrgUnitSettingDto> {
    return await this.api
      .get<OrgUnitSettingDto>(`/orgUnits/${orgUnitId}/settings`)
      .then(x => x.data);
  }

  async updateOrgUnitSetting(
    data: OrgUnitSettingDto
  ): Promise<OrgUnitSettingDto> {
    return this.api
      .put<OrgUnitSettingDto>(`/orgUnits/${data.orgUnitId}/settings`, data)
      .then(x => x.data);
  }

  async getEncounterExtension(
    encounterId: string
  ): Promise<EncounterExtensionDto | undefined> {
    return (
      await this.api.get<EncounterExtensionDto>(
        `encounter/extensions/${encounterId}`
      )
    ).data;
  }

  postVerifyPinRequest(data: UserAccountVerifyPinRequestDto): Promise<boolean> {
    return this.api
      .post<boolean>("userAccounts/$verifyPin", data)
      .then(x => x.data);
  }

  async checkEncounter(key: string, patientId: string): Promise<boolean> {
    return (
      await this.api.get<boolean>(
        `/me/storage/checkEncounter/${key}/${patientId}`
      )
    ).data;
  }

  async getTemplateExtension(
    templateId: string
  ): Promise<TemplateExtensionDto | undefined> {
    return await this.api
      .get<TemplateExtensionDto>(`/template/extensions/${templateId}`)
      .then(x => x.data);
  }

  async getAllTemplateExtension(): Promise<TemplateExtensionDto[]> {
    return await this.api
      .get<TemplateExtensionDto[]>("/template/extensions")
      .then(x => x.data);
  }

  async getCurrentUserQuickColours(): Promise<QuickColoursDto | undefined> {
    return this.api
      .get("/me/quickColours") // remember to update
      .then(result => result.data)
      .catch(catchNotFoundError);
  }
  async updateCurrentUserQuickColours(
    value: UpdateQuickColoursDto
  ): Promise<QuickColoursDto> {
    const response = await this.api
      .post<QuickColoursDto>("/me/quickColours", value)
      .then(x => x.data);
    return response;
  }
}
