import { getAccessToken } from "@bps/http-client";
import { DateTime, delay } from "@bps/utils";
import { StorageProperties } from "@libs/constants/storage-properties.ts";
import { getOrAdd } from "@libs/utils/utils.ts";
import {
  HttpClient,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
  LogLevel
} from "@microsoft/signalr";

import { Entity } from "./Entity.ts";
import { EntityEventData } from "./EntityEventData.ts";
import { EntitySubscriberHandler } from "./EntitySubscriberHandler.ts";
import { EventAction } from "./EventAction.ts";
import { HubGatewayConfig } from "./HubGatewayConfig.ts";
import { HubHttpClient } from "./HubHttpClient.ts";

/**
 * EventData represents the data received from receiveMessage method
 */
export interface EventData<T = any> {
  eventId: string;
  /**
   * format is DomainName.EntityName.ID for entity update/create
   */
  subject: string;
  action: EventAction;
  tenantId: string;
  /**
   * For entities, key is the id.
   */
  key: T;
  username: string;
  timestamp: string;
  correlationId: string;
  partitionKey: string;
  /**
   * Has the whole entity object
   */
  value?: object;
  userId?: string;
  source?: string;
  etag?: string;
}

/**
 * Gateway that exposes events coming from SignalR Hub
 *
 */
export class HubGateway implements IHubGateway {
  config: HubGatewayConfig;
  private connectionOptions: IHttpConnectionOptions = {};
  private hubConnection?: HubConnection = undefined;
  private establishConnection = false;
  private httpClient: HttpClient;
  private subscribersMap = new Map<Entity, Set<EntitySubscriberHandler<any>>>();

  /**
   *
   * @param baseUrl Base URL to the SignalR Hubs
   * @param options Additional options
   *
   */
  constructor(
    private baseUrl: string,
    options: Partial<HubGatewayConfig>
  ) {
    this.config = Object.assign(
      { autoReconnect: false, autoReconnectDelayMs: 5000 },
      options
    );

    this.httpClient = new HubHttpClient({
      accessTokenFactory: () =>
        getAccessToken(true, () =>
          localStorage.removeItem(StorageProperties.locked)
        )
    });

    this.connectionOptions = {
      httpClient: this.httpClient
    };
  }

  /**
   * Stops the connection to SignalR
   * @returns {Promise<void>} A Promise that resolves when the connection has been successfully terminated, or rejects with an error.
   */
  async disconnect(): Promise<void> {
    this.establishConnection = false;
    if (this.hubConnection) {
      await this.hubConnection.stop();
      this.hubConnection = undefined;
      this.config.autoReconnect = false;
    }
  }

  /**
   * Connects to SignalR
   * @returns {Promise<void>} A Promise that resolves when the connection has been successfully terminated, or rejects with an error.
   */
  async connect(): Promise<void> {
    this.establishConnection = true;
    if (this.hubConnection === undefined) {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(this.baseUrl, this.connectionOptions)
        .configureLogging(LogLevel.Information) // this may need to be changed or parameterized
        .build();

      this.hubConnection.onclose(error => {
        if (error) {
          // eslint-disable-next-line no-console
          console.error(error.message);
        }
        return this.handleReconnection();
      });

      this.hubConnection.on("receiveMessage", this.onMessageReceived);
    }

    try {
      await this.hubConnection.start();
      if (
        this.hubConnection &&
        this.hubConnection.state === HubConnectionState.Connected
      ) {
        await this.init();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error.message);
      await this.handleReconnection();
    }
  }

  /**
   * Subscribes to events for a given entity.
   * The subscription can be created before the gateway is connected.
   *
   * @param entity The entity type the handler will receive events about
   * @param handler the handler that will be called when an event is received
   */
  onEntityEvent(entity: Entity, handler: EntitySubscriberHandler<any>) {
    const subscribers = getOrAdd(this.subscribersMap, entity, () => new Set());
    subscribers.add(handler);
  }

  unsubscribe(entity: Entity, handler: EntitySubscriberHandler) {
    this.subscribersMap.get(entity)?.forEach((func, key) => {
      if (func === handler) {
        this.subscribersMap.get(entity)?.delete(key);
      }
    });
  }

  private init() {
    return this.httpClient.post(`${this.baseUrl}/init`);
  }

  private shouldReconnect() {
    return this.establishConnection && this.shouldReconnect;
  }

  private async handleReconnection() {
    if (this.shouldReconnect()) {
      await delay(this.config.autoReconnectDelayMs).then(() => {
        if (!this.config.autoReconnect) {
          return;
        }

        return this.connect();
      });
    }
  }

  private onMessageReceived = (event: EventData) => {
    const entityEvent = toEntityEventData(event);
    if (!entityEvent) {
      if (
        !entityEvent &&
        !import.meta.env.PROD &&
        Object.values(EventAction).includes(event.action)
      ) {
        // eslint-disable-next-line no-console
        console.warn(
          `Ignoring Hub message subject ${event.subject}. Not recognized as an entity message.`
        );
      }
      return;
    }

    const handlers = this.subscribersMap.get(entityEvent.entity);
    if (!handlers) {
      return;
    }

    handlers.forEach(handler => {
      try {
        handler(entityEvent);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });
  };
}

export const toEntityEventData = (
  event: EventData
): EntityEventData | undefined => {
  const subjectParts = event.subject.split(".");
  // Subject is expected in the format DomainName.EntityName.ID
  if (subjectParts.length !== 3) {
    return undefined;
  }

  const [domain, entityName, id] = subjectParts;
  // Id is required
  if (!id) {
    return undefined;
  }

  const entity = `${domain}.${entityName}` as Entity;

  return {
    action: event.action,
    entity,
    etag: event.etag,
    key: event.key,
    id,
    tenantId: event.tenantId,
    timestamp: DateTime.jsDateFromISO(event.timestamp) || DateTime.jsDateNow()
  };
};

export interface IHubGateway {
  disconnect(): Promise<void>;
  connect(options: IHttpConnectionOptions): Promise<void>;
  onEntityEvent<T extends any>(
    entity: Entity,
    handler: EntitySubscriberHandler<T>
  ): void;
  unsubscribe(entity: Entity, handler: EntitySubscriberHandler): void;
}
