import {
  DefaultHttpClient,
  HttpRequest,
  HttpResponse,
  ILogger,
  LogLevel
} from "@microsoft/signalr";

import { createLogger } from "./Loggers.ts";

interface HubHttpClientConfig {
  accessTokenFactory: () => Promise<string | undefined>;
  logger?: ILogger | LogLevel | undefined;
  tenant?: string;
}

/**
 * Wraps the DefaultHttpClient from @microsoft/signalr
 * to be able to add the tenant as a HTTP header to any request
 * as well as to add the access token when calling negotiate or when used
 * directly
 */
export class HubHttpClient extends DefaultHttpClient {
  private config: HubHttpClientConfig;

  constructor(config: HubHttpClientConfig) {
    super(createLogger(config.logger));
    this.config = config;
  }

  async send(request: HttpRequest): Promise<HttpResponse> {
    const extraHeaders = {};
    // Once connected, SignalR uses the client to pass the Signalr auth
    // token, we shouldn't override it if it is set
    if (!request.headers || !request.headers["Authorization"]) {
      const token = await this.config.accessTokenFactory();

      if (token) {
        extraHeaders["Authorization"] = `Bearer ${token}`;
      }
    }

    request.headers = { ...request.headers, ...extraHeaders };
    return super.send(request);
  }
}
