import { appContext } from "@libs/config/app-context.utils.ts";
import {
  ApplicationInsights,
  IConfiguration
} from "@microsoft/applicationinsights-web";

/**
 * creates the AppInsights instance and sets it on the AppContext
 * @param appInsightConfig additional app insight configuration
 */
export const initAppInsights = (appInsightConfig?: IConfiguration) => {
  const appInsights = new ApplicationInsights({
    config: {
      enableAutoRouteTracking: false,
      disableFetchTracking: false,
      ...appContext.config.appInsights,
      ...appInsightConfig
    }
  });
  appContext.appInsights = appInsights;
  appInsights.loadAppInsights();
};
