const DEFAULT_BATCH_SIZE = 100;

export async function batchRequests<T>(
  ids: string[],
  requestCallback: (ids: string[]) => Promise<T[]>,
  options?: { batchSize: number }
): Promise<T[]> {
  const batchSize = options?.batchSize || DEFAULT_BATCH_SIZE;

  const unbatchedIds = Array.from(ids);
  const batchedIds: string[][] = [];
  for (let i = 0; i < Math.ceil(ids.length / batchSize); i++) {
    batchedIds.push(unbatchedIds.splice(0, batchSize));
  }

  const batchedResults = await Promise.all(
    batchedIds.map(batch => requestCallback(batch))
  );

  return batchedResults.reduce((results, result) => results.concat(result), []);
}
