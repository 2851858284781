export enum EventAction {
  Adjusted = "Adjust",
  Update = "Update",
  TrivialUpdate = "TrivialUpdate",
  StatusUpdate = "StatusUpdate",
  Create = "Create",
  Delete = "Delete",
  UpdateRelationship = "UpdateRelationship",
  CalendarEventUpdate = "CalendarEventUpdate",
  CalendarEventRecurrenceProjected = "Projected",
  DocumentCommitted = "Committed",
  InvoiceStatusUpdate = "InvoiceStatusUpdate",
  Cancelled = "Cancel",
  UpdateAggregate = "UpdatedAggregate",
  Commit = "ClinicalDataCommit"
}
