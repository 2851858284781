import { action, computed, observable } from "mobx";
import {
  fromPromise,
  FULFILLED,
  IPromiseBasedObservable,
  isPromiseBasedObservable,
  PENDING,
  REJECTED
} from "mobx-utils";

import { IMaybePromiseObservable } from "@libs/utils/promise-observable/promise-observable.utils.ts";

export class MaybePromiseWrapper<T> implements IMaybePromiseObservable<T> {
  @observable wrappedPromise?: IPromiseBasedObservable<T>;

  constructor(promise?: PromiseLike<T>) {
    this.set(promise);
  }

  @computed
  get value() {
    if (!this.wrappedPromise) {
      return undefined;
    }
    return this.fulfilled ? this.wrappedPromise.value : undefined;
  }

  @computed
  get fulfilled() {
    return !!this.wrappedPromise && this.wrappedPromise.state === FULFILLED;
  }

  @computed
  get rejected() {
    return !!this.wrappedPromise && this.wrappedPromise.state === REJECTED;
  }

  @computed
  get pending() {
    return !!this.wrappedPromise && this.wrappedPromise.state === PENDING;
  }

  @computed
  get error(): Error | undefined {
    return this.rejected
      ? this.wrappedPromise && (this.wrappedPromise.value as Error)
      : undefined;
  }

  @computed
  get pendingOrFulfilled() {
    return this.pending || this.fulfilled;
  }

  @computed
  get hasPromise() {
    return !!this.wrappedPromise;
  }

  @action
  clear() {
    this.wrappedPromise = undefined;
  }

  @action
  set(promise?: PromiseLike<T>) {
    if (!promise) {
      this.wrappedPromise = undefined;
    } else if (isPromiseBasedObservable(promise)) {
      this.wrappedPromise = promise;
    } else {
      this.wrappedPromise = fromPromise(promise);
    }
  }
}
