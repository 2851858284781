import {
  AuthorisationError,
  AxiosError,
  ConflictError,
  HttpStatusCode,
  IMiddleware,
  NotFoundError,
  RequestError,
  ServerError
} from "@bps/http-client";
import { ERROR_TYPE_HEADER } from "@libs/api/api.constants.ts";
import { DuplicateError } from "@libs/api/errors/DuplicateError.ts";
import { NetworkError } from "@libs/api/errors/NetworkError.ts";

import { ErrorType } from "../error-type.enum.ts";

export class ExceptionFilterMiddleware implements IMiddleware<AxiosError> {
  constructor(private traceId?: string) {}
  public onRejected = (error: AxiosError): Promise<AxiosError> => {
    if (error.response) {
      // Last TraceID used by AppInsights. It should correspond to the AJAX Request operation id.
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const errorType: ErrorType | undefined =
        error.response.headers && error.response.headers[ERROR_TYPE_HEADER];

      if (error.response.status >= HttpStatusCode.InternalServerError) {
        return Promise.reject(
          new ServerError(
            error.response.status,
            error.response.data,
            this.traceId
          )
        );
      }

      if (error.response.status === HttpStatusCode.NotFound) {
        return Promise.reject(
          new NotFoundError(error.response.data, this.traceId)
        );
      }

      if (error.response.status === HttpStatusCode.Conflict) {
        return Promise.reject(
          new ConflictError(
            error.response.status,
            error.response?.data,
            this.traceId
          )
        );
      }

      if (
        errorType === ErrorType.ValidationError ||
        errorType === ErrorType.BusinessError ||
        errorType === ErrorType.ConflictError
      ) {
        return Promise.reject(
          new RequestError(error.response.data, this.traceId)
        );
      }

      if (errorType === ErrorType.AuthorizationError) {
        return Promise.reject(
          new AuthorisationError(
            error.response?.status,
            error.response?.data,
            this.traceId
          )
        );
      }

      if (errorType === ErrorType.DuplicateError) {
        return Promise.reject(
          new DuplicateError(
            error.response.status,
            error.message,
            error.response?.data,
            this.traceId
          )
        );
      }

      if (error.request) {
        // The request was made but no response was received or the response could not be read (missing CORS header)
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return Promise.reject(
          new NetworkError(error.request?.status, error.request?.message)
        );
      }
    }
    return Promise.reject(
      new RequestError(error.response?.statusText, this.traceId)
    );
  };
}
