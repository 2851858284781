import { Baggage } from "@libs/analytics/app-insights/app-insights.types.ts";
import {
  CORRELATION_BAGGAGE_HEADER,
  IF_MATCH_HEADER
} from "@libs/api/api.constants.ts";

export function setCorrelationBaggageHeader(options: {
  eTag?: string;
  baggage?: Baggage;
}) {
  const { baggage, eTag } = options;

  const headersObject = {};

  if (baggage) {
    const encoded = btoa(JSON.stringify(baggage));
    headersObject[CORRELATION_BAGGAGE_HEADER] = encoded;
  }

  if (eTag) {
    headersObject[IF_MATCH_HEADER] = eTag;
  }

  return {
    headers: headersObject
  };
}
